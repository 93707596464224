import React from "react";
import './style.scss'

const CustomButton = (props) => {
    const { label, onClick } = props;

    return (
        <div className="custom-button" onClick={() => onClick()}>{label} </div>
    )
}

export default CustomButton