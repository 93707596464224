import { SearchOutlined } from "@ant-design/icons";
import { Avatar, Button, Checkbox } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../../common/CustomInput";
import './style.scss';

const NewPod = (props) => {
    const [podName, setPodName] = useState(null);
    const [podAdministrator, setPodAdministrator] = useState(null);
    const [creationDate, setCreationDate] = useState(null);
    const [status, setStatus] = useState(null)
    const navigate = useNavigate()

    return (
        <div className="new-pod-container">
            <div className="new-pod-header">
                New Pod
                <div className='cancel-project' onClick={() => navigate('/pods')}>Cancel</div>
            </div>

            <div className="inner-container">
                <div className="card-results">
                    <CustomInput half label="Pod Name" value={podName} onChange={setPodName} />
                    <CustomInput half label="Pod Administrator" value={podAdministrator} onChange={setPodAdministrator} />
                    <CustomInput half label="Creation Date" value={creationDate} onChange={setCreationDate} />
                    <CustomInput half label="Status" value={status} onChange={setStatus} />
                </div>

                <div className="pod-details">
                    <div className="pod-details-item">
                        <div className="header">
                            <div className="search-icon"><SearchOutlined /></div>
                            <input placeholder="Search" className='input-area' />
                        </div>
                        <div className="pod-details-inner">
                            <div className="hapto-text-dark">Departments</div>
                            <div className="department-item"><Checkbox>Design</Checkbox></div>
                            <div className="department-item"><Checkbox>Devops</Checkbox></div>
                            <div className="department-item"><Checkbox>Finance</Checkbox></div>
                            <div className="department-item"><Checkbox>Utilities</Checkbox></div>
                        </div>
                    </div>
                    <div className="pod-details-item pod-details-large">
                        <div className="header">
                            <Checkbox>Select All</Checkbox>
                        </div>
                        <div className="pod-details-inner">
                            {[1, 2, 3, 4, 5].map(item => {
                                return (
                                    <div key={item} className="selected-item hapto-flex">
                                        <div className="profile-flex">
                                            <Checkbox className="selected-checkbox" />
                                            <Avatar size="medium" src="https://joeschmoe.io/api/v1/random" />
                                            <div className="profile-desc">
                                                <div className="profile-name">Karthick Anbu</div>
                                                <div className="profile-role">Developer</div>
                                            </div>
                                        </div>
                                        <div className="hapto-text-light">Design</div>
                                        <div className="hapto-text-light">A12</div>
                                        <div className="status hapto-text-light">Active</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="pod-details-item">
                        <div className="header hapto-flex">
                            <div className="hapto-text-dark">Selected Team Members</div>
                            <div className="selected-members">4</div>
                        </div>
                        <div className="pod-details-inner">
                            {[1, 2, 3, 4].map(item => {
                                return (
                                    <div key={item} className="selected-item">
                                        <div className="profile-flex">
                                            <Avatar size="medium" src="https://joeschmoe.io/api/v1/random" />
                                            <div className="profile-desc">
                                                <div className="profile-name">Karthick Anbu</div>
                                                <div className="profile-role">Developer</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className='hapto-action-buttons'>
                    <Button className='submit-button' onClick={() => null}>Save Changes</Button>
                </div>
            </div>
        </div>
    )
}

export default NewPod