import { Pie, measureTextWidth } from '@ant-design/charts';
import React from 'react';
import './style.scss'

const DonutChart = (props) => {
    const chartColorCodes = ['#E14B5A', '#F39223', '#4C9BEB', '#4BEB5B'];
    const chartData = [
        {
            type: 'Active',
            value: 20,
        },
        {
            type: 'Pending',
            value: 20,
        },
        {
            type: 'Completed',
            value: 40,
        },
        {
            type: 'Overdue',
            value: 20,
        },
    ]

    function renderStatistic(containerWidth, text, style) {
        const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
        const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

        let scale = 0.5;

        if (containerWidth < textWidth) {
            scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
        }

        const textStyleStr = `width:${containerWidth}px;`;
        return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
    }

    const config = {
        appendPadding: 10,
        data: chartData,
        angleField: 'value',
        colorField: 'type',
        radius: 1,
        innerRadius: 0.69,
        legend: false,
        color: chartColorCodes,
        meta: {
            value: {
                formatter: (v) => `${v} ¥`,
            },
        },
        label: {
            type: 'inner',
            offset: '-50%',
            style: {
                textAlign: 'center',
            },
            autoRotate: false,
            content: '',
        },
        statistic: {
            title: {
                offsetY: -4,
                customHtml: (container, view, datum) => {
                    const { width, height } = container.getBoundingClientRect();
                    const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
                    const text = datum ? datum.type : 'Total';
                    return renderStatistic(d, '20%', {
                        fontSize: 16,
                    });
                },
            },
            content: {
                offsetY: 4,
                style: {
                    fontSize: '22px',
                },
                customHtml: (container, view, datum, data) => {
                    const { width } = container.getBoundingClientRect();
                    const text = datum ? `Rs ${datum.value}` : `Rs ${data.reduce((r, d) => r + d.value, 0)}`;
                    return renderStatistic(width, 'Goals Achieved', {
                        fontSize: 12,
                    });
                },
            },
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
            {
                type: 'pie-statistic-active',
            },
        ],
    };

    return <div className='donut-chart-container'>
        <div className='chart'>
            <Pie {...config} />
        </div>
        <div className='legend-container'>
            {chartData?.map((item, index) => {
                return (
                    <div key={index} className='hapto-flex legend-item'>
                        <div className='legend-color' style={{backgroundColor: chartColorCodes[index]}}></div>
                        <div className='hapto-text-dark'>{item?.type}</div>
                    </div>
                )
            })}
        </div>
    </div>
}

export default DonutChart