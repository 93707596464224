import { DownOutlined, UploadOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import React, { useState } from 'react';
import { PodsFileList } from './data';
import './style.scss';

const PodsFiles = (props) => {
    const [shared, setShared] = useState(null)

    return (
        <div className='pods-files-container'>
            <div className='pods-files-left-container'>
                <input className='search-input' placeholder='Search for files' />

                <div className='files-list'>
                    {PodsFileList.map((item, index) => {
                        return (
                            <div className='file-item' index={index}>
                                {item.image}
                                <div className='file-details'>
                                    <div className='hapto-text-dark'>{item.name}</div>
                                    <div className='hapto-text-light'>{item.date}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className='filter-container'>
                <div className='hapto-text-dark filter-header'>Filter</div>
                <DatePicker className='select-style' placeholder="Upload Date" />
                <div className='file-size-container'>
                    <input className='input-area filter-margin' placeholder='File Size' />
                    <div className='mb'>mb</div>
                </div>

                <div className='radio-item hapto-text-dark' onClick={() => setShared('me')}>
                    <div className='selection'>{shared === 'me' && <div className='inner' />}</div>
                    Shared with me
                </div>
                <div className='radio-item hapto-text-dark' onClick={() => setShared('by')}>
                    <div className='selection'>{shared === 'by' && <div className='inner' />}</div>
                    Shared by me
                </div>
                <div className='radio-item hapto-text-dark filter-margin' onClick={() => setShared('group')}>
                    <div className='selection'>{shared === 'group' && <div className='inner' />}</div>
                    Uploaded by group
                </div>

                <div className='other-filter-items'>
                    <div className='hapto-text-dark'>File Formats</div>
                    <div className='bottom-arrow'>
                        <DownOutlined className='arrow' />
                    </div>
                </div>
                <div className='other-filter-items filter-margin'>
                    <div className='hapto-text-dark'>Original Uploaders</div>
                    <div className='bottom-arrow'>
                        <DownOutlined className='arrow' />
                    </div>
                </div>

                <div className='hapto-text-dark filter-header'>Upload Files</div>
                <div className='upload-area'>
                    <UploadOutlined />
                    <div className='hapto-text-dark'>Drag and drop files here</div>
                </div>
            </div>
        </div>
    )
}

export default PodsFiles