import { QUICK_ACTIONS_FULL_SCREEN_MEETING_ROOM, QUICK_ACTIONS_MEETING_ROOM, QUICK_ACTIONS_SHOW_CREATE } from "./constants";

export const saveMeetingRoomQuickAction = payload => {
    return {
        type: QUICK_ACTIONS_MEETING_ROOM,
        payload,
    };
};

export const saveFullScreenMeetingRoomQuickAction = payload => {
    return {
        type: QUICK_ACTIONS_FULL_SCREEN_MEETING_ROOM,
        payload,
    };
};

export const saveShowCreateModal = payload => {
    return {
        type: QUICK_ACTIONS_SHOW_CREATE,
        payload,
    };
};

