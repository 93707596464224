import React, { useEffect, useState } from 'react';
import './style.scss';
import HaptoLogo from '../../assets/images/navbar/slikk-logo.png';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar } from 'antd';
import { CopyrightOutlined } from '@ant-design/icons';
import { saveMeetingRoomQuickAction } from '../../redux/quickactions/actions';

const Navbar = (props) => {
    const { navItems } = props;
    const [currentPath, setCurrentPath] = useState('/home')
    const navigate = useNavigate();
    const location = useLocation()

    useEffect(() => {
        setCurrentPath(location?.pathname)
    }, [location?.pathname])

    const handleNavChange = (key) => {
        props.showMeetingRoom(false);
        navigate(`/${key}`)
    }

    return (
        <div className='navbar-container'>
            <div className='logo-wrapper'>
                <div className='logo-flex'>
                    <img src={HaptoLogo} className="logo-img" alt="logo" />
                </div>
            </div>

            {
                navItems?.map((nav, index) => {
                    if (!nav.hide) return (
                        <div
                            key={index}
                            onClick={() => handleNavChange(nav?.key)}
                            className={`nav-item ${currentPath?.includes(nav.key) ? 'active-nav' : ''}`}>
                            <div className="nav-img">
                                <img src={nav?.image} style={nav?.key === 'profile' ? { width: '60%', height: '60%' } : {}}  className="image-fit" alt="nav" />
                            </div>
                            <div>{nav?.name}</div>
                            {nav?.active && <div className='active-border' />}
                        </div>
                    )
                    else return null
                })
            }

            <div className='profile-container'>
                <Avatar size="small" src="https://joeschmoe.io/api/v1/random" />
                <div className='profile-name'>Karthick Anbu</div>
            </div>

            <div className='copyright-container'>
                <div className='company-name'>HaptoGroup</div>
                <div className='copyright'><CopyrightOutlined className='copy' />2022 All Rights Reserved</div>
            </div>
        </div >
    )
}


const mapDispatchToProps = {
    showMeetingRoom: saveMeetingRoomQuickAction
};

const mapStateToProps = state => {
    return {
        navItems: state.navbar.items,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);