import { CloseOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import React, { useState } from "react";
import CustomInput from "../../common/CustomInput";
import { NotificationData } from "./data";
import './style.scss'

const Notifications = (props) => {
    const [departments, setDepartments] = useState(['Design', 'Development', 'Marketing']);
    const [teamMembers, setTeamMembers] = useState(['Karthick', 'Anbu', 'AK']);
    const [pods, setPods] = useState(['Pod 1', 'Pod 2', 'Pod 3']);
    const [activeTab, setActiveTab] = useState('All')

    return (
        <div className="notification-container">
            <div className="filter-container">
                <div className="filter-head">FILTERS</div>
                <div className="filter-section">
                    <div className="hapto-flex">
                        <div className="text-dark">Departments</div>
                        <div className="text-light clear-all">Clear all</div>
                    </div>
                    <CustomInput value={'Design'} onChange={() => null} />
                    <div className="filter-list">
                        {departments?.map((item, index) => {
                            return (
                                <div key={index} className="filter-item text-dark">{item}
                                    <div className="close-icon">
                                        <CloseOutlined />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="filter-section">
                    <div className="hapto-flex">
                        <div className="text-dark">Team Members</div>
                        <div className="text-light clear-all">Clear all</div>
                    </div>
                    <CustomInput value={'AK'} onChange={() => null} />
                    <div className="filter-list">
                        {teamMembers?.map((item, index) => {
                            return (
                                <div key={index} className="filter-item text-dark">{item}
                                    <div className="close-icon">
                                        <CloseOutlined />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="filter-section">
                    <div className="hapto-flex">
                        <div className="text-dark">Pods</div>
                        <div className="text-light clear-all">Clear all</div>
                    </div>
                    <CustomInput value={'Pod 1'} onChange={() => null} />
                    <div className="filter-list">
                        {pods?.map((item, index) => {
                            return (
                                <div key={index} className="filter-item text-dark">{item}
                                    <div className="close-icon">
                                        <CloseOutlined />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <div className="notify-wrapper">
                <div className="tab-container hapto-flex">
                    <div className="hapto-flex">
                        <div
                            onClick={() => setActiveTab('All')}
                            className="tab-style text-dark">
                            All
                            {activeTab === "All" && <div className="active-tab" />}
                        </div>
                        <div
                            onClick={() => setActiveTab('Projects')}
                            className="tab-style text-dark">
                            Projects <span className="project-color" />
                            {activeTab === "Projects" && <div className="active-tab" />}
                        </div>
                        <div onClick={() => setActiveTab('Tasks')}
                            className="tab-style text-dark">
                            Tasks <span className="task-color" />
                            {activeTab === "Tasks" && <div className="active-tab" />}
                        </div>
                    </div>
                    <div onClick={() => setActiveTab('Archived')}
                        className="tab-style text-dark">
                        Archived
                        {activeTab === "Archived" && <div className="active-tab" />}
                    </div>
                </div>

                {NotificationData?.filter(elem => activeTab === 'All' || elem.category === activeTab).map((item, index) => {
                    return (
                        <div key={index} className="notification-item">
                            <div className="notification-type">{item.type}</div>
                            <Avatar size="small" src="https://joeschmoe.io/api/v1/random" />
                            <div className="item-margin">
                                <div className="text-dark name-margin">{item.name} <div className={item.category === 'Projects' ? 'project-color' : 'task-color'} /></div>
                                <div className="text-light">{item.desc}</div>
                                <div className="time">{item.time}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Notifications