import React, { useState } from 'react';
import './style.scss';
import { useNavigate } from "react-router-dom";
import AuthLayout from '../authlayout';
import OtpInput from './input';
import { Button, message } from 'antd';
import BGImage from '../../assets/images/authentication/hapto-background.png'

const OTPScreen = (props) => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState('')

    const handleValidate = () => {
        navigate('/onboarding')
    }

    return (
        <AuthLayout>
            <div className='otp-screen-container'>
                <img src={BGImage} alt="bg-image" className="login-bg-image" />
                <div className='sign-up-wrapper'>
                    <div className='new-user'>Already Registered?</div>
                    <div className='sign-up' onClick={() => navigate('/login')}>
                        Log In
                    </div>
                </div>
                <div className='card-wrapper'>
                    <div className='bg-dots'>
                        {Array(144).fill().map((_, index) => {
                            return <div key={index} className='dot-item' />
                        })}
                    </div>
                    <div className='otp-message'>Please enter the one time password sent to your phone to verify your account</div>
                    <div className='phone-message'>OTP has been sent to 98*****678</div>

                    <div className='otp-container'>
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            separator={<span> </span>}
                        />
                    </div>
                    <Button className='validate-button' onClick={handleValidate}>Validate</Button>

                    <div className='resend-code' onClick={() => message.success("OTP Resent")}>Resend Code?</div>
                </div>
            </div>
        </AuthLayout>
    )
}

export default OTPScreen