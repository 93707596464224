import { ArrowLeftOutlined, DownOutlined, SearchOutlined, UpOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import React, { useState } from 'react';
import ChatDetails from './details';
import './style.scss';
import MessageIcon from '../../assets/images/chat/message.png'

const Chat = (props) => {

    const [showFullChatWindow, setShowFullChatWindow] = useState(false);
    const [activeTab, setActiveTab] = useState('contacts')
    const [isDetails, setIsDetails] = useState(false);

    return (
        <div className={`chat-window-container ${showFullChatWindow ? 'chat-full-window' : ''}`}>
            <div className='header-container hapto-flex'>
                <div className='hapto-flex'>
                    {isDetails && <div className='back-button' onClick={() => setIsDetails(false)}>
                        <ArrowLeftOutlined />
                    </div>}
                    <div className='profile-badge'>
                        <Avatar size="small" src="https://joeschmoe.io/api/v1/random" />
                        <div className='online' />
                    </div>
                    <div className='hapto-text-dark head'>Chats</div>
                </div>
                <div className='toggle-chat' onClick={() => setShowFullChatWindow(!showFullChatWindow)}>
                    {showFullChatWindow ? <DownOutlined /> : <UpOutlined />}
                </div>
            </div>

            {showFullChatWindow && <div className='full-window-container'>
                {isDetails ? <ChatDetails activeTab={activeTab} /> : <div>

                    <div className='hapto-flex tab-container'>
                        <img src={MessageIcon} alt="message" className="message-icon"/>

                        <div className={`chat-tab-item ${activeTab === 'contacts' ? 'active-tab' : ''}`} onClick={() => setActiveTab('contacts')}>
                            All Contacts
                        </div>
                        <div className={`chat-tab-item ${activeTab === 'groups' ? 'active-tab' : ''}`} onClick={() => setActiveTab('groups')}>
                            Groups
                        </div>
                        <div className={`chat-tab-item ${activeTab === 'add' ? 'active-tab' : ''}`} onClick={() => setActiveTab('add')}>
                            +
                        </div>
                    </div>

                    <div className='search-area-container'>
                        <input placeholder={activeTab === 'groups' ? 'Type here...' : "Search"}className='search-area' />
                        <SearchOutlined className="search-icon"/>
                    </div>

                    {activeTab === 'contacts' && <div className='chat-list'>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(index => {
                            return (
                                <div className='chat-item' key={index} onClick={() => setIsDetails(true)}>
                                    <Avatar size="small" src="https://joeschmoe.io/api/v1/random" />
                                    <div className='chat-details'>
                                        <div className='hapto-text-dark chat-name'>Alex Johnson</div>
                                        <div className='status'>
                                            <div className='color' />
                                            <div className='status-name'>Available</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>}

                    {activeTab === 'groups' && <div className='group-list'>
                        {[1, 2, 3, 4].map(index => {
                            return (
                                <div className='group-item' key={index} onClick={() => setIsDetails(true)}>
                                    Group {index}
                                </div>
                            )
                        })}
                    </div>}

                    {activeTab === 'add' && <div className='chat-list'>
                        <div className='hapto-text-dark custom-chat'>Custom Chat Group</div>
                        <input placeholder='Name your custom group' className='input-area' />

                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(index => {
                            return (
                                <div className='chat-item hapto-flex' key={index} onClick={() => null}>
                                    <div className='hapto-flex'>
                                        <Avatar size="small" src="https://joeschmoe.io/api/v1/random" />
                                        <div className='chat-details'>
                                            <div className='hapto-text-dark chat-name'>Alex Johnson</div>
                                            <div className='status'>
                                                <div className='color' />
                                                <div className='status-name'>Available</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='add-chat hapto-text-dark'>Add</div>
                                </div>
                            )
                        })}
                    </div>}
                </div>}
            </div>}
        </div>
    )
}

export default Chat