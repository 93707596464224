import { Select } from 'antd';
import React from 'react';
import { StatusList } from '../../../constants/lov';
import ColumnChart from '../../reports/charts/column';
import DonutChart from '../../reports/charts/donut';
import EitherSideChart from '../../reports/charts/eitherside';
import StackedColumn from '../../reports/charts/stackedColumn';
import StackedProgress from '../../reports/charts/stackedProgress';
import './styles.scss';

const timeData = [
    { label: 'Planned Completion', id: 1, value: 100 },
    { label: 'Actual Completion', id: 2, value: 50 },
    { label: 'Behind Schedule', id: 3, value: -50 },
]

const progressData = [
    { label: 'Hayden', status: [{ label: 'Completed', id: 1, value: 60 }, { label: 'Overdue', id: 3, value: 40 }] },
    { label: 'Aspen', status: [{ label: 'Completed', id: 1, value: 30 }, { label: 'Remaining', id: 2, value: 30 }, { label: 'Overdue', id: 3, value: 40 }] },
    { label: 'Jacob', status: [{ label: 'Remaining', id: 2, value: 80 }, { label: 'Overdue', id: 3, value: 20 }] },
    { label: 'Holder', status: [{ label: 'Completed', id: 1, value: 60 }, { label: 'Remaining', id: 2, value: 10 }, { label: 'Overdue', id: 3, value: 30 }] },
]

const columnData = [
    { label: 'Task 1', status: [{ label: 'Completed', id: 1, value: 60 }, { label: 'In Process', id: 2, value: 80 }, { label: 'On Hold', id: 3, value: 20 }] },
    { label: 'Task 2', status: [{ label: 'Completed', id: 1, value: 30 }, { label: 'In Process', id: 2, value: 60 }, { label: 'On Hold', id: 3, value: 40 }] },
    { label: 'Task 3', status: [{ label: 'Completed', id: 1, value: 80 }, { label: 'In Process', id: 2, value: 30 }, { label: 'On Hold', id: 3, value: 70 }] },
    { label: 'Task 4', status: [{ label: 'Completed', id: 1, value: 60 }, { label: 'In Process', id: 2, value: 50 }, { label: 'On Hold', id: 3, value: 50 }] },
]

const ProductivityAnalytics = (props) => {

    return (
        <div className='productivity-analytics-container'>
            <div className='productivity-filter-container'>
                <div className='hapto-text-dark filter-head'>Filter</div>

                <div className='hapto-flex'>
                    <div className='hapto-flex-inner-item'>
                        <div className='hapto-text-dark pod-label'>By Pod</div>
                        <Select className='select-style' placeholder="Pod" />
                    </div>
                    <div className='hapto-flex-inner-item'>
                        <div className='hapto-text-dark pod-label'>By Dept</div>
                        <Select className='select-style' placeholder="Department" />
                    </div>
                    <div className='hapto-flex-inner-item'>
                        <div className='hapto-text-dark pod-label'>By Design</div>
                        <Select className='select-style' placeholder="Design" />
                    </div>
                    <div className='selection-flex'>
                        <div className='hapto-text-dark filter-head'>Select Quarter</div>
                        <div className='hapto-flex-selection'>
                            <div className='selection-item hapto-text-dark'>Q1</div>
                            <div className='selection-item hapto-text-dark'>Q2</div>
                            <div className='selection-item hapto-text-dark'>Q3</div>
                            <div className='selection-item hapto-text-dark'>Q4</div>
                        </div>
                    </div>
                </div>
                <div className='hapto-flex'>
                    <div className='hapto-flex-inner-item'>
                        <div className='hapto-text-dark pod-label'>By Name</div>
                        <Select className='select-style' placeholder="Name" />
                    </div>
                    <div className='hapto-flex-inner-item'>
                        <div className='hapto-text-dark pod-label'>By Status</div>
                        <Select className='select-style' placeholder="Status" options={StatusList} />
                    </div>
                    <div className='hapto-flex-inner-item'>
                        <div className='hapto-text-dark pod-label'>By Status</div>
                        <Select className='select-style' placeholder="Status" options={StatusList} />
                    </div>
                    <div className='selection-flex'>
                        <div className='hapto-text-dark filter-head'>Select Project</div>
                        <div className='hapto-flex-selection'>
                            <div className='selection-item selection-project hapto-text-dark'>Project 1</div>
                            <div className='selection-item selection-project hapto-text-dark'>Project 2</div>
                            <div className='selection-item selection-project hapto-text-dark'>Project 3</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='productivity-chart-container'>
                <div className='chart-left-item'>
                    <div className='donut-chart-section'>
                        <div className='count-container'>
                            <div className='count'>4</div>
                            <div className='hapto-text-dark count-text'>Active Goals</div>
                        </div>
                        <DonutChart />
                    </div>
                </div>
                <div className='top-right'>
                    <div className='hapto-text-dark card-head' style={{ marginBottom: '20px' }}>Goals Timeline</div>
                    <ColumnChart />
                </div>
            </div>

            <div className='productivity-chart-container scroll-view'>
                <div className='stacked-column-chart-container'>
                    <div className='card-container'>
                        <div className='hapto-text-dark card-head'>Active tasks</div>
                        <StackedColumn chartData={columnData} />
                    </div>
                </div>
                <div className='hapto-flex area-donut-wrapper'>
                    <div className='card-container task-container'>
                        <div className='hapto-text-dark card-head'>Task Workload</div>
                        <StackedProgress chartData={progressData} />
                    </div>

                    <div className='card-container time-container'>
                        <div className='hapto-text-dark card-head'>Time</div>
                        <EitherSideChart chartData={timeData} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductivityAnalytics