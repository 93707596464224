import React from 'react';
import './style.scss';
import CalendarView from './calendar';

const GanttScreen = (props) => {

    return (
        <div className='gantt-container'>
            <div className='gantt-header hapto-flex'>
                <div className='status-list hapto-flex'>
                    <div className='status-item'>
                        <div className='status-color' style={{ backgroundColor: '#8833FF' }} />
                        <div className='hapto-text-light'>To Do</div>
                    </div>
                    <div className='status-item'>
                        <div className='status-color' style={{ backgroundColor: '#33BFFF' }} />
                        <div className='hapto-text-light'>In Progress</div>
                    </div>
                    <div className='status-item'>
                        <div className='status-color' style={{ backgroundColor: '#FFCB33' }} />
                        <div className='hapto-text-light'>Review</div>
                    </div>
                </div>
            </div>

            <CalendarView />
        </div>
    )
}

export default GanttScreen