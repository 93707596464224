export const FeedData = [
    {
        name: 'John Doe',
        desc: 'has completed the ',
        time: '2 mins ago',
        likes: 5,
        commendations: 2,
        comments: [
            {
                message: 'Nice start, I will take over from here on',
                person: 'Lisa S',
                time: 'Just now'
            },
            {
                message: 'I will be providing support on that',
                person: 'Cathy Y',
                time: 'Just now'
            }
        ]
    },
    {
        name: 'John Doe',
        desc: 'has completed the ',
        time: 'Just now',
        likes: 5,
        commendations: 1,
        comments: []
    },
    {
        name: 'John Doe',
        desc: 'has completed the ',
        time: '2 days ago',
        likes: 5,
        commendations: 1,
        comments: [
            {
                message: 'I will be providing support on that',
                person: 'Cathy Y',
                time: '2 hrs ago'
            },
            {
                message: 'Nice start, I will take over from here on',
                person: 'Lisa S',
                time: '20 mins ago'
            },
        ]
    }
]

export const TaskItem = [
    {
        task: 'Task 1',
        desc: 'Complete user research for new website',
        complete: true
    },
    {
        task: 'Space Task 2',
        desc: 'Search Engine Optimization',
        complete: false
    },
    {
        task: 'Space Task 3',
        desc: 'Getting free Rubicity',
        complete: false
    },
    {
        task: 'Space Task 4',
        desc: 'Complete user research for new website',
        complete: false
    }
]

export const GoalsItem = [
    {
        name: 'Goal 1',
        priority: 'High Priority',
        status: 'Active',
        desc: 'Achieve 20% increase in revenue in 4th quarter',
        owner: 'Martha Wayne',
        Assigne: 5,
        progress: 31,
        start_date: '2/1/2022',
        end_date: '28/10/2022'
    }
]