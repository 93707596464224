import { CloseOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import { Avatar, Button, DatePicker, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { PriorityList, StatusList } from '../../../constants/lov';
import './style.scss';

const EditTasks = (props) => {
    const { close } = props;

    return (
        <div className='edit-tasks-container'>
            <div className='hapto-flex'>
                <div className='hapto-flex'>
                    <EditOutlined />
                    <div className='hapto-text-dark head-label'>Edit Task</div>
                </div>
                <div className='hapto-text-dark delete-task' onClick={() => close()}><CloseOutlined /></div>
            </div>

            <div className='text-area-label hapto-text-dark'>Task Description</div>
            <TextArea className='text-area' autoSize />

            <div className='other-inputs'>
                <div className='other-item'>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Task Type</div>
                        <Select className='select-style' />
                    </div>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Start Date</div>
                        <DatePicker className='select-style' />
                    </div>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Due Date</div>
                        <DatePicker className='select-style' />
                    </div>
                </div>

                <div className='other-item'>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Priority</div>
                        <Select className='select-style' options={PriorityList}/>
                    </div>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Status</div>
                        <Select className='select-style' options={StatusList}/>
                    </div>
                </div>
            </div>

            <div className='text-area-label hapto-text-dark'>Objectives</div>
            <div className='objective-item hapto-flex'>
                <div className='hapto-text-dark'>Investigate five firms in the vicinity</div>
                <div className='objective-box'/>
            </div>

            <div className='objective-item hapto-flex'>
                <div className='hapto-text-dark'>Investigate five firms in the vicinity</div>
                <div className='objective-box'/>
            </div>

            <Button className='add-button' onClick={() => null}>+ Add Objective</Button>


            <div className='text-area-label hapto-text-dark'>Subtasks</div>
            <div className='new-subtask-modal'>
                <div className='hapto-text-dark text-area-label'>Subtask Description</div>
                <input className='select-style' />
            </div>
            <Button className='add-button' onClick={() => null}>+ Add Subtasks</Button>

            <div className='text-area-label hapto-text-dark'>Comments</div>
            <div className='comments-container'>
                <TextArea className='text-area' autoSize placeholder='Type here...' />
                <div className='hapto-action-buttons comment-buttons'>
                    <Button className='submit-button' onClick={() => null}>Comment</Button>
                </div>
            </div>

            {[1, 2].map(item => {
                return (
                    <div className='comment-item' key={item}>
                        <div className='comment-flex'>
                            <Avatar size="medium" src="https://joeschmoe.io/api/v1/random" />
                            <div>
                                <div className='comment-flex'>
                                    <div className='hapto-text-dark comment-margin'>Haroid</div>
                                    <div className='hapto-text-light comment-margin'>4 March 2.15 pm</div>
                                </div>
                                <div className='hapto-text-dark comment-margin'>Complete two firms today</div>
                            </div>
                        </div>
                    </div>
                )
            })}
            <div className='show-more'>
                Show More
                <div><DownOutlined /></div>
            </div>

            <div className='hapto-action-buttons'>
                <Button className='submit-button cancel' onClick={() => close()}>Cancel</Button>
                <Button className='submit-button' onClick={() => close()}>Save</Button>
            </div>
        </div>
    )
}

export default EditTasks