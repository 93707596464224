import { CaretDownOutlined, FilterFilled, PlusCircleOutlined } from '@ant-design/icons';
import { Avatar, Button, Progress } from 'antd';
import React, { useState } from 'react';
import { GoalsList } from './data';
import './style.scss';
import CustomModal from '../../common/CustomModal';
import NewGoals from './newgoals';
import EditGoal from './editgoal';
import GoalsFilter from './filter';

const Goals = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false)
        ;
    const getBackground = (key) => {
        let color = ''
        if (key === 'active') color = '#9CDBF5'
        else if (key === 'achieved') color = '#A5FECB'
        else if (key === '30days') color = '#FED764'
        else color = '#D5CEF2'
        return { backgroundColor: color }
    }

    return (
        <div className='goals-container'>
            {showModal && <CustomModal close={() => setShowModal(false)}>
                <NewGoals close={() => setShowModal(false)} />
            </CustomModal>}
            {showEditModal && <CustomModal close={() => setShowEditModal(false)}>
                <EditGoal close={() => setShowEditModal(false)} />
            </CustomModal>}
            {showFilterModal && <CustomModal close={() => setShowFilterModal(false)}>
                <GoalsFilter close={() => setShowFilterModal(false)} />
            </CustomModal>}
            <div className='goals-inner-container'>
                <div className='goals-header'>
                    <div className="goals-header-item" style={getBackground('active')}>
                        <div className='item-number'>4</div>
                        <div className='item-desc'>Active Goals</div>
                    </div>
                    <div className="goals-header-item" style={getBackground('achieved')}>
                        <div className='item-number'>16</div>
                        <div className='item-desc'>Goals Achieved</div>
                    </div>
                    <div className="goals-header-item" style={getBackground('30days')}>
                        <div className='item-number'>2</div>
                        <div className='item-desc'>Goals Due in 30 days</div>
                    </div>
                    <div className="goals-header-item" style={getBackground('total')}>
                        <div className='item-number'>20%</div>
                        <div className='item-desc'>Total Goal Progress</div>
                    </div>
                    <Button className='create-button' onClick={() => setShowModal(true)}><PlusCircleOutlined />New Goal</Button>
                </div>

                <div className='list-header'>
                    <div className='header-name'>Current Goals</div>
                    <Button className='filter-button' onClick={() => setShowFilterModal(true)}><FilterFilled /> Filter <CaretDownOutlined /></Button>
                </div>

                {GoalsList?.map((item, index) => {
                    return (
                        <div className='goal-item' key={index}>
                            <div className='left-item'>
                                <div className='flex-view'>
                                    <div className='flex-view'>
                                        <div className='goal-name'>{item.name}</div>
                                        <div className='goal-priority'><div className='priority'></div>{item.priority}</div>
                                    </div>
                                    <div className='goal-status'>{item?.status}</div>
                                </div>
                                <div className='goal-desc'>{item.desc}</div>
                            </div>

                            <div className='middle-view'>
                                <div className='middle-section'>
                                    <div className='section-name'>Owner</div>
                                    <Avatar size="small" src="https://joeschmoe.io/api/v1/random" />
                                    <div className='profile-name'>{item.owner}</div>
                                </div>

                                <div className='middle-section  margin-zero'>
                                    <div className='section-name'>Assigne</div>
                                    <Avatar.Group size="small">
                                        <Avatar src="https://joeschmoe.io/api/v1/random" />
                                        <Avatar src="https://joeschmoe.io/api/v1/random" />
                                        <Avatar src="https://joeschmoe.io/api/v1/random" />
                                    </Avatar.Group>
                                    <div className='profile-name'>+ {item.Assigne} others</div>
                                </div>
                            </div>

                            <div className='right-view'>
                                <div className='edit-goal' onClick={() => setShowEditModal(true)}>Edit Goal</div>
                                <div className='flex-view'>
                                    <div className='goal-light'>Goal Progress</div>
                                    <div className='goal-bold'>{item.progress}%</div>
                                </div>
                                <Progress strokeColor="#5433FF" percent={item.progress} showInfo={false} />
                                <div className='flex-view'>
                                    <div className='goal-bold'>Start Date <span className='goal-light'>{item.start_date}</span></div>
                                    <div className='goal-bold'>End Date <span className='goal-light'>{item.end_date}</span></div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Goals