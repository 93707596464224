export const AllPodsData = [
    {
        id: 1,
        name: 'Pod 1',
        active: true,
        admin: 'Samuel Hayden',
        admin_role: 'Manager',
        members: 6,
        created_date: '6/4/2020',
        departments: ['Development', 'others', 'others', 'others', 'others'],
        projects: ['Project Alpha', 'others', 'others', 'others', 'others']
    },
    {
        id: 2,
        name: 'Pod 1',
        active: true,
        admin: 'Samuel Hayden',
        admin_role: 'Manager',
        members: 6,
        created_date: '6/4/2020',
        departments: ['Development', 'others', 'others', 'others', 'others'],
        projects: ['Project Alpha', 'others', 'others', 'others', 'others']
    },
    {
        id: 3,
        name: 'Pod 1',
        active: true,
        admin: 'Samuel Hayden',
        admin_role: 'Manager',
        members: 6,
        created_date: '6/4/2020',
        departments: ['Development', 'others', 'others', 'others', 'others'],
        projects: ['Project Alpha', 'others', 'others', 'others', 'others']
    }
]