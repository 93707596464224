import { Input } from "antd";
import React from "react";
import './style.scss'
import { EyeInvisibleOutlined, EyeTwoTone, SearchOutlined } from '@ant-design/icons';

const CustomInput = (props) => {
    const { label, placeholder, value, onChange, protect, required, half, three, search } = props;
    return (
        <div className="input-container" style={{ width: half ? '48%' : three ? '32%' : '100%' }}        >
            <div className="input-label">{label}{required ? ' *' : ''}</div>
            {search && <div className="search-icon"><SearchOutlined /></div>}
            {!protect ? <Input
                placeholder={placeholder}
                value={value} onChange={(event) => onChange(event?.target?.value)}
                style={search && {paddingLeft:30}}
            /> :
                <Input.Password
                    placeholder={placeholder}
                    value={value}
                    onChange={(event) => onChange(event?.target?.value)}
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />}
        </div>
    )
}

export default CustomInput