import { FilterOutlined } from "@ant-design/icons";
import { Button, Select } from "antd";
import React from "react";
import './style.scss';

const GoalsFilter = (props) => {
    const { close } = props;

    return (
        <div className="goals-filter-container">
            <div className="hapto-flex filter-head">
                <div className="hapto-flex">
                    <FilterOutlined style={{ marginRight: 5 }} />
                    Filter
                </div>
                <div className="hapto-text-dark cancel" onClick={() => close()}>Cancel</div>
            </div>

            <div className="filter-list">
                <div className="filter-item">
                    <div className="hapto-text-dark label-width">Status</div>
                    <Select className='select-style' />
                </div>
                <div className="filter-item">
                    <div className="hapto-text-dark label-width">Goal</div>
                    <Select className='select-style' />
                </div>
                <div className="filter-item">
                    <div className="hapto-text-dark label-width">Owner</div>
                    <Select className='select-style' />
                </div>
                <div className="filter-item">
                    <div className="hapto-text-dark label-width">Assignee</div>
                    <Select className='select-style' />
                </div>
            </div>

            <div className='hapto-action-buttons inner-padding'>
                <Button className='submit-button' onClick={() => close()}>Save</Button>
            </div>
        </div>
    )
}

export default GoalsFilter