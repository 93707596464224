import { CloudUploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import './style.scss';

const NewPoll = (props) => {
    const { close } = props;

    return (
        <div className='new-poll-container'>
            <div className='hapto-flex'>
                <div className='new-poll'><CloudUploadOutlined style={{ marginRight: 5 }} />New Poll</div>
                <div className='delete-poll'>Delete Post</div>
            </div>

            <div className='hapto-text-dark'>Post Question</div>
            <TextArea className='text-area' autoSize placeholder='Type here!!' />

            <div className='hapto-text-dark'>Options</div>
            <div className='option-item'>
                <div className='added-option' />
                <div className='hapto-text-dark'>Option 1</div>
            </div>
            <div className='option-item'>
                <div className='added-option' />
                <div className='hapto-text-dark'>Option 2</div>
            </div>
            <Button className='add-button'>+ Add Option</Button>

            <div className='hapto-action-buttons'>
                <Button className='submit-button cancel' onClick={() => close()}>Cancel</Button>
                <Button className='submit-button' onClick={() => close()}>Save</Button>
            </div>
        </div>
    )
}

export default NewPoll