import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './style.scss';
import Add from '../../assets/images/topbar/add.svg';
import Calendar from '../../assets/images/topbar/calendar.svg';
import Mail from '../../assets/images/topbar/mail.svg';
import Notification from '../../assets/images/topbar/notification.svg';
import Settings from '../../assets/images/topbar/settings.svg';
import Star from '../../assets/images/topbar/star.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import HaptoLogo from '../../assets/images/navbar/slikk-logo.png';


const TopBar = (props) => {
    const { navItems } = props;
    const [active, setActive] = useState(null);
    const navigate = useNavigate();
    const location = useLocation()

    useEffect(() => {
        configureActive(location?.pathname)
    }, [location?.pathname])

    const configureActive = (currentPath) => {
        const findNav = navItems?.find(elem => currentPath?.includes(elem.key))
        setActive(findNav)
    }

    return (
        <div className='topbar-container'>
            <div className='logo-wrapper'>
                <div className='logo-flex'>
                    <img src={HaptoLogo} className="logo-img" alt="logo" />
                </div>
            </div>

            <div className='left-item'>
                <div className="nav-img">
                    <img src={active?.image} style={active?.key === 'profile' ? { width: '60%', height: '60%' } : {}} className="image-fit" alt="nav" />
                </div>
                <div className='nav-name'>{active?.name || 'Home'}</div>
            </div>

            <div className='right-item'>
                <div className='link-section'>
                    <img src={Add} className="top-add-img" alt="nav" />
                </div>
                <div className='link-section'>
                    <div className='message'>12</div>
                    <img src={Star} className="top-nav-img" alt="nav" />
                </div>
                <div className='link-section'>
                    <div className='message'>1</div>
                    <img src={Mail} className="top-nav-img" alt="nav" />
                </div>
                <div className='link-section'>
                    <div className='message low'>10</div>
                    <img src={Calendar} className="top-nav-img" alt="nav" />
                </div>
                <div className='link-section' onClick={() => navigate('/notifications')}>
                    <div className='message low'>5</div>
                    <img src={Notification} className="top-nav-img" alt="nav" />
                </div>
                <div className='link-section'>
                    <img src={Settings} className="top-nav-img" alt="nav" />
                </div>
            </div>
        </div>
    )
}


const mapDispatchToProps = {
};

const mapStateToProps = state => {
    return {
        navItems: state.navbar.items,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);