export const TeamsData = [
    {
        name: 'Karthick Anbu',
        role: 'Software Developer',
        empid: 'A12',
        access: 'Employee',
        status: 'Active',
        podstatus: 'Pod 1',
        department: 'Department',
        joiningdate: '06/12/2022'
    },
    {
        name: 'Karthick Anbu',
        role: 'Software Developer',
        empid: 'A12',
        access: 'Employee',
        status: 'Active',
        podstatus: 'Pod 1',
        department: 'Department',
        joiningdate: '06/12/2022'
    },
    {
        name: 'Karthick Anbu',
        role: 'Software Developer',
        empid: 'A12',
        access: 'Employee',
        status: 'Active',
        podstatus: 'Pod 1',
        department: 'Department',
        joiningdate: '06/12/2022'
    },
    {
        name: 'Karthick Anbu',
        role: 'Software Developer',
        empid: 'A12',
        access: 'Employee',
        status: 'Active',
        podstatus: 'Pod 1',
        department: 'Department',
        joiningdate: '06/12/2022'
    },
    {
        name: 'Karthick Anbu',
        role: 'Software Developer',
        empid: 'A12',
        access: 'Employee',
        status: 'Active',
        podstatus: 'Pod 1',
        department: 'Department',
        joiningdate: '06/12/2022'
    },
    {
        name: 'Karthick Anbu',
        role: 'Software Developer',
        empid: 'A12',
        access: 'Employee',
        status: 'Active',
        podstatus: 'Pod 1',
        department: 'Department',
        joiningdate: '06/12/2022'
    }
]