import React from 'react';
import './style.scss';

const StackedColumn = (props) => {
    const { chartData } = props;
    const chartColor = ['#F39223', '#FFDC2E', '#EFEFEF'];

    const getPercentage = (now, max) => {
        return (now / max) * 100;
    };

    const getMax = (list) => {
        let max = 0;
        list?.map((inner) => (max = max + inner.value));
        return max;
    };

    return (
        <div className='stacked-column-container'>
            <div className='status-list hapto-flex'>
                {chartData[1].status.map((item, index) => {
                    return (
                        <div key={index} className='status-item'>
                            <div className='status-color' style={{ backgroundColor: chartColor[index] }} />
                            <div className='hapto-text-light'>{item.label}</div>
                        </div>
                    )
                })}
            </div>

            {chartData.map((data, index) => {
                return (
                    <div className='chart-item hapto-flex' key={index}>
                        <div className='hapto-text-dark label-text'>{data.label}</div>
                        <div className='bar-list'>
                            <div className='bar-item'>
                                <div className='bar-inner-top-value' style={{width: `${data.status[0]?.value}%`}}/>
                            </div>
                            <div className='bar-item'>
                                <div className='bar-inner-bottom-value' style={{width: `${data.status[1]?.value}%`}}/>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default StackedColumn