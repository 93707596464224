import React from 'react';
import './style.scss';
import '../pods/feed/newmeeting/style.scss';
import '../pods/feed/newevent/style.scss';
import { AudioOutlined, CloseSquareOutlined, ExpandOutlined, FullscreenExitOutlined, LinkOutlined, PhoneOutlined, SendOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { connect } from 'react-redux';
import { saveFullScreenMeetingRoomQuickAction, saveMeetingRoomQuickAction } from '../../redux/quickactions/actions';
import ShareIcon from '../../assets/images/home/share.png'

const MeetingRoom = (props) => {
    const { fullScreenMeetingRoom, fullScreenMode } = props

    return (
        <div className='meeting-room-container' style={fullScreenMeetingRoom ? { width: '100%', left: 0 } : {}}>
            <div className='meeting-inner-container'>
               {!fullScreenMeetingRoom && <div className='meeting-message'>
                    Morning Briefing at 10:00 AM IST
                </div>}

                <div className='meeting-container' style={fullScreenMeetingRoom ? { height: '100%' } : {}}>
                    Please wait for host to accept your join request

                    <div className='expand-view' onClick={() => fullScreenMode(!fullScreenMeetingRoom)}>
                        {fullScreenMeetingRoom ? <FullscreenExitOutlined /> : <ExpandOutlined />}
                    </div>

                    <div className='meeting-bottom-tab'>
                        <div className='action-item'>
                            <AudioOutlined className="action-image" />
                        </div>
                        <div className='action-item'>
                            <VideoCameraOutlined className="action-image" />
                        </div>
                        <div className='action-item'>
                            {/* <ShareAltOutlined className="action-image" /> */}
                            <img src={ShareIcon} alt="share" className="action-image"/>
                        </div>
                        <div className='action-item'>
                            <PhoneOutlined className="action-image" style={{ color: '#FF9191' }} />
                        </div>

                        <div className='end-call' onClick={() => props.showMeetingRoom(false)}>
                            <CloseSquareOutlined className="action-image" style={{ color: '#FF9191' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='meeting-details-container'>
                <div className='inner-details'>
                    <div className='meeting-profile hapto-flex'>
                        <div className='hapto-flex'>
                            <Avatar size="medium" src="https://joeschmoe.io/api/v1/random" />
                            <div className='details-style'>
                                <div className='hapto-text-dark'>Holden Mcgroin</div>
                                <div className='hapto-text-light'>9.45 AM</div>
                            </div>
                        </div>
                        <div className='call-status'>Joining</div>
                    </div>

                    <div className='members-list'>
                        <div className='hapto-text-dark members-head'>Members on call</div>
                        <div className='meeting-profile hapto-flex'>
                            <div className='hapto-flex'>
                                <Avatar size="medium" src="https://joeschmoe.io/api/v1/random" />
                                <div className='details-style'>
                                    <div className='hapto-text-dark'>Holden Mcgroin</div>
                                    <div className='hapto-text-light'>9.45 AM</div>
                                </div>
                            </div>
                            <div className='call-status'>Online</div>
                        </div>
                        <div className='meeting-profile hapto-flex'>
                            <div className='hapto-flex'>
                                <Avatar size="medium" src="https://joeschmoe.io/api/v1/random" />
                                <div className='details-style'>
                                    <div className='hapto-text-dark'>Holden Mcgroin</div>
                                    <div className='hapto-text-light'>9.45 AM</div>
                                </div>
                            </div>
                            <div className='call-status'>Online</div>
                        </div>
                        <div className='meeting-profile hapto-flex'>
                            <div className='hapto-flex'>
                                <Avatar size="medium" src="https://joeschmoe.io/api/v1/random" />
                                <div className='details-style'>
                                    <div className='hapto-text-dark'>Holden Mcgroin</div>
                                    <div className='hapto-text-light'>9.45 AM</div>
                                </div>
                            </div>
                            <div className='call-status'>Online</div>
                        </div>
                    </div>

                    <div className='send-message-container hapto-flex'>
                        <div className='link-container'>
                            <LinkOutlined className='link-icon' />
                        </div>
                        <input className="input-area" placeholder="Type here..." />
                        <div className='link-container'>
                            <SendOutlined className="link-icon" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = {
    showMeetingRoom: saveMeetingRoomQuickAction,
    fullScreenMode: saveFullScreenMeetingRoomQuickAction
};

const mapStateToProps = state => {
    return {
        fullScreenMeetingRoom: state.quickAction.fullScreenMeetingRoom
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingRoom);