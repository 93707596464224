import { AntCloudOutlined, UpOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import './style.scss'

const Onboarding = (props) => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(0)
    const onBoardScreens = [
        { label: 'Welcome Holden!', id: 0 },
        { label: 'Customize your Profile Icon', id: 1 },
        { label: 'Choose a color for your theme', id: 2 },
        { label: 'How many people are in your organization?', id: 3 },
        { label: 'How did we peak your interest?', id: 4 },
        { label: 'Select your industry?', id: 5 },
        { label: 'We are all done, enjoy!', id: 6 },
    ]

    return (
        <div className="onboarding-container">
            <div className="onboarding-content-container">
                <div>
                    <div className="hapto-text-dark content-head">{onBoardScreens[activeTab].label}</div>
                    {activeTab === 0 && <div className="onboard-details">
                        <div className="hapto-text-dark">First things first, lets begin with a few steps to get you started</div>
                        <Button className='onboarding-button' onClick={() => setActiveTab(1)}>Let's Begin</Button>
                    </div>}

                    {activeTab === 1 && <div className="onboard-details">
                        <div className="onboard-profile-icon">
                            <div className="upload-area">
                                <AntCloudOutlined className="upload-icon" />
                                Drop image here or browse
                            </div>
                            <div className="or-wrapper">
                                <div className="or-border" />
                                or
                                <div className="or-border" />
                            </div>
                            <div className="profile-color">H</div>
                            <div className="color-list">
                                <div className="color-item" style={{ backgroundColor: '#5433FF' }} />
                                <div className="color-item" style={{ backgroundColor: '#C83030' }} />
                                <div className="color-item" style={{ backgroundColor: '#E14B5A' }} />
                                <div className="color-item" style={{ backgroundColor: '#F39223' }} />
                                <div className="color-item" style={{ backgroundColor: '#FFDC2E' }} />
                                <div className="color-item" style={{ backgroundColor: '#158220' }} />
                                <div className="color-item" style={{ backgroundColor: '#22C933' }} />
                                <div className="color-item" style={{ backgroundColor: '#4BEB5B' }} />
                                <div className="color-item" style={{ backgroundColor: '#1E0B82' }} />
                                <div className="color-item" style={{ backgroundColor: '#4C9BEB' }} />
                            </div>
                        </div>
                        <div className="button-flex">
                            <Button className='nobordered-button' onClick={() => setActiveTab(0)}>Previous</Button>
                            <Button className='onboarding-button' onClick={() => setActiveTab(2)}>Continue</Button>
                        </div>
                    </div>}

                    {activeTab === 2 && <div className="onboard-details">
                        <div className="onboard-profile-icon">
                            <div className="profile-color">H</div>
                            <div className="color-list">
                                <div className="color-item" style={{ backgroundColor: '#5433FF' }} />
                                <div className="color-item" style={{ backgroundColor: '#C83030' }} />
                                <div className="color-item" style={{ backgroundColor: '#E14B5A' }} />
                                <div className="color-item" style={{ backgroundColor: '#F39223' }} />
                                <div className="color-item" style={{ backgroundColor: '#FFDC2E' }} />
                                <div className="color-item" style={{ backgroundColor: '#158220' }} />
                                <div className="color-item" style={{ backgroundColor: '#22C933' }} />
                                <div className="color-item" style={{ backgroundColor: '#4BEB5B' }} />
                                <div className="color-item" style={{ backgroundColor: '#1E0B82' }} />
                                <div className="color-item" style={{ backgroundColor: '#4C9BEB' }} />
                            </div>
                        </div>
                        <div className="button-flex">
                            <Button className='nobordered-button' onClick={() => setActiveTab(1)}>Previous</Button>
                            <Button className='onboarding-button' onClick={() => setActiveTab(3)}>Continue</Button>
                        </div>
                    </div>}

                    {activeTab === 3 && <div className="onboard-details">
                        <div className="onboard-select-list">
                            <div className="onboard-select-item">Just Me</div>
                            <div className="onboard-select-item">2 - 5</div>
                            <div className="onboard-select-item">6 - 10</div>
                            <div className="onboard-select-item">11 - 20</div>
                            <div className="onboard-select-item">21 - 40</div>
                            <div className="onboard-select-item">41- 100</div>
                            <div className="onboard-select-item">100+</div>
                            <div className="onboard-select-item">Not Sure</div>
                        </div>
                        <div className="button-flex">
                            <Button className='nobordered-button' onClick={() => setActiveTab(2)}>Previous</Button>
                            <Button className='onboarding-button' onClick={() => setActiveTab(4)}>Continue</Button>
                        </div>
                    </div>}

                    {activeTab === 4 && <div className="onboard-details">
                        <div className="onboard-select-list">
                            <div className="onboard-select-item">Search Engine</div>
                            <div className="onboard-select-item">Google Adverts</div>
                            <div className="onboard-select-item">Facebook Adverts</div>
                            <div className="onboard-select-item">Televison</div>
                            <div className="onboard-select-item">Newspaper</div>
                            <div className="onboard-select-item">Billboards</div>
                            <div className="onboard-select-item">Others</div>
                        </div>
                        <div className="button-flex">
                            <Button className='nobordered-button' onClick={() => setActiveTab(3)}>Previous</Button>
                            <Button className='onboarding-button' onClick={() => setActiveTab(5)}>Continue</Button>
                        </div>
                    </div>}

                    {activeTab === 5 && <div className="onboard-details">
                        <div className="onboard-select-list">
                            <div className="onboard-select-item ">Technology</div>
                            <div className="onboard-select-item">Finance</div>
                            <div className="onboard-select-item">Software</div>
                            <div className="onboard-select-item">Retail</div>
                            <div className="onboard-select-item">Production</div>
                            <div className="onboard-select-item">Construction</div>
                            <div className="onboard-select-item">Others</div>
                        </div>
                        <div className="button-flex">
                            <Button className='nobordered-button' onClick={() => setActiveTab(4)}>Previous</Button>
                            <Button className='onboarding-button' onClick={() => setActiveTab(6)}>Continue</Button>
                        </div>
                    </div>}

                    {activeTab === 6 && <div className="onboard-details">
                        <div className="button-flex">
                            <Button className='nobordered-button' onClick={() => setActiveTab(5)}>Previous</Button>
                            <Button className='onboarding-button' onClick={() => navigate('/home')}>Continue</Button>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="image-container" />
        </div>
    )
}

export default Onboarding