export const NotificationData = [
    {
        name: 'Jessica Doe has created the task Task 1',
        desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At.',
        time: '6/4/2020 at 2:30 pm',
        category: 'Tasks',
        type: 'Pod 1'
    },
    {
        name: 'Jessica Doe has created the task Task 1',
        desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At.',
        time: '6/4/2020 at 2:30 pm',
        category: 'Tasks',
        type: 'Pod 1'
    },
    {
        name: 'Jessica Doe has created the Projects 1',
        desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At.',
        time: '6/4/2020 at 2:30 pm',
        category: 'Projects',
        type: 'Pod 1'
    },
    {
        name: 'Jessica Doe has created the Projects 1',
        desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At.',
        time: '6/4/2020 at 2:30 pm',
        category: 'Projects',
        type: 'Pod 1'
    },
    {
        name: 'Jessica Doe has created the Projects 1',
        desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At.',
        time: '6/4/2020 at 2:30 pm',
        category: 'Projects',
        type: 'Pod 1'
    },
    {
        name: 'Jessica Doe has created the Projects 1',
        desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At.',
        time: '6/4/2020 at 2:30 pm',
        category: 'Projects',
        type: 'Pod 1'
    }
]