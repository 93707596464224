import { CaretDownOutlined, FilterFilled } from "@ant-design/icons";
import { Avatar, Button } from "antd";
import React, { useState } from "react";
import CustomModal from "../../../common/CustomModal";
import { PodsCompletedListData, PodsListData } from "./data";
import './style.scss';
import PodsListFilter from './filter'

const PodsList = (props) => {
    const [showModal, setShowModal] = useState(false);

    const getPriorityColor = (priority) => {
        let backgroundColor = '#FED764'
        if (priority === 'Low') backgroundColor = '#BFB1F9'
        else if (priority === 'Medium') backgroundColor = '#4C9BEB'
        return { backgroundColor }
    }

    return (
        <div className="pods-lists-container">
            {showModal && <CustomModal close={() => setShowModal(false)}>
                <PodsListFilter close={() => setShowModal(false)} />
            </CustomModal>}
            <div className="filter-wrapper">
                <Button className='filter-button' onClick={() => setShowModal(true)}><FilterFilled /> Filter <CaretDownOutlined /></Button>
            </div>
            <div className="head-row">
                <div className="head-desc name-width">Task Description</div>
                <div className="head-desc other-width">Task Type</div>
                <div className="head-desc other-width">Due Date</div>
                <div className="head-desc other-width">Assigned To</div>
                <div className="head-desc other-width">Department</div>
                <div className="head-desc other-width">Priority</div>
                <div className="head-desc other-width">Status</div>
            </div>
            {PodsListData?.map((item, index) => {
                return (
                    <div key={index} className="row-item">
                        <div className="item-desc name-width">{item?.desc}</div>
                        <div className="item-desc other-width">{item?.type}</div>
                        <div className="item-desc other-width">{item?.due}</div>
                        <div className="item-desc other-width">
                            <Avatar size="small" src="https://joeschmoe.io/api/v1/random" />
                        </div>
                        <div className="item-desc other-width">{item?.department}</div>
                        <div className="item-desc priority-width">
                            <div className="priority-color" style={getPriorityColor(item.priority)} />
                            {item?.priority}
                        </div>
                        <div className="item-desc other-width">{item?.status}</div>
                    </div>
                )
            })}

            <div>Completed Tasks</div>
            <div className="head-row">
                <div className="head-desc name-width">Task Description</div>
                <div className="head-desc other-width">Task Type</div>
                <div className="head-desc other-width">Completed On</div>
                <div className="head-desc other-width">Assigned To</div>
                <div className="head-desc other-width">Department</div>
                <div className="head-desc other-width">Priority</div>
                <div className="head-desc other-width">Status</div>
            </div>
            {PodsCompletedListData?.map((item, index) => {
                return (
                    <div key={index} className="row-item">
                        <div className="item-desc name-width">{item?.desc}</div>
                        <div className="item-desc other-width">{item?.type}</div>
                        <div className="item-desc other-width">{item?.due}</div>
                        <div className="item-desc other-width">
                            <Avatar size="small" src="https://joeschmoe.io/api/v1/random" />
                        </div>
                        <div className="item-desc other-width">{item?.department}</div>
                        <div className="item-desc priority-width">
                            <div className="priority-color" style={getPriorityColor(item.priority)} />
                            {item?.priority}
                        </div>
                        <div className="item-desc other-width">{item?.status}</div>
                    </div>
                )
            })}

        </div>
    )
}

export default PodsList