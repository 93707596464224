import { AudioOutlined, FileImageOutlined, FilePdfOutlined, VideoCameraOutlined } from "@ant-design/icons";
import './style.scss'

export const PodsFileList = [
    {
        image: <FilePdfOutlined className="pdf-style" />,
        name: 'Progress Report.pdf',
        date: '20/4/2022'
    },
    {
        image: <VideoCameraOutlined className="video-style" />,
        name: 'Demo Video.mp4',
        date: '20/4/2022'
    },
    {
        image: <FileImageOutlined className="image-style" />,
        name: 'Image Chart.jpg',
        date: '20/4/2022'
    },
    {
        image: <AudioOutlined className="audio-style" />,
        name: 'Audio File.mp3',
        date: '20/4/2022'
    },
    {
        image: <FilePdfOutlined className="pdf-style" />,
        name: 'Progress Report.pdf',
        date: '20/4/2022'
    },
    {
        image: <VideoCameraOutlined className="video-style" />,
        name: 'Demo Video.mp4',
        date: '20/4/2022'
    },
    {
        image: <FileImageOutlined className="image-style" />,
        name: 'Image Chart.jpg',
        date: '20/4/2022'
    },
    {
        image: <AudioOutlined className="audio-style" />,
        name: 'Audio File.mp3',
        date: '20/4/2022'
    },
    {
        image: <FilePdfOutlined className="pdf-style" />,
        name: 'Progress Report.pdf',
        date: '20/4/2022'
    },
    {
        image: <VideoCameraOutlined className="video-style" />,
        name: 'Demo Video.mp4',
        date: '20/4/2022'
    },
    {
        image: <FileImageOutlined className="image-style" />,
        name: 'Image Chart.jpg',
        date: '20/4/2022'
    },
    {
        image: <AudioOutlined className="audio-style" />,
        name: 'Audio File.mp3',
        date: '20/4/2022'
    },

]