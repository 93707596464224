import Home from '../assets/images/navbar/home.png';
import MyTasks from '../assets/images/navbar/mytask.png';
import Pods from '../assets/images/navbar/pods.png';
import Goals from '../assets/images/navbar/goals.png';
import Profile from '../assets/images/navbar/profile.png';
import Projects from '../assets/images/navbar/projects.png';
import Notification from '../assets/images/topbar/notification.svg';

const ReduxInitialState = {
    navbar: {
        items: [
            { name: 'Home', id: 1, key: 'home', image: Home },
            { name: 'My Tasks', id: 2, key: 'mytasks', image: MyTasks},
            { name: 'Pods', id: 3, key: 'pods', image: Pods },
            { name: 'Goals', id: 4, key: 'goals', image: Goals },
            { name: 'Projects', id: 5, key: 'projects', image: Projects},
            { name: 'Reporting & Analytics ', id: 6, key: 'reportsandanalytics', image: MyTasks},
            { name: 'Profile', id: 7, key: 'profile', image: Profile },
            { name: 'Notifications', id: 7, key: 'notifications', image: Notification, hide:true },
        ]
    },
    quickAction:{
        showMeetingRoom: false,
        fullScreenMeetingRoom: false,
        showCreate: false
    }
}

export default ReduxInitialState