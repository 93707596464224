import { LinkOutlined, SearchOutlined, SendOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import './style.scss';

const messageList = [
    { message: 'a different story . was was the color of television the story', isSend: true },
    { message: 'burn a different story I a different story to from various people', isSend: false },
    { message: 'had the story . burn . was', isSend: true },
    { message: 'The sky All . It a different story I', isSend: false },
    { message: 'had bit by bit happens a pleasure . All', isSend: true },
    { message: 'was The sky was as generally a pleasure a dead channel', isSend: false },
    { message: 'All The sky to in such cases All burn', isSend: true },
    { message: 'bit by bit All bit by bit I a different story It', isSend: false },
    { message: 'had a different story . . . .All the color of television it as generally was a dead channel', isSend: true },
    { image: 'https://images.pexels.com/photos/12258113/pexels-photo-12258113.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load', isSend: false },
    { message: 'had the story . burn . was', isSend: true },
]

const ChatDetails = (props) => {
    const messagesRef = useRef(null);
    const { activeTab } = props;
    const [messagesToShow, setMessagesToShow] = useState([]);

    useEffect(() => {
        moveChatToBottom();
    }, [])

    const moveChatToBottom = () => {

        if (messagesRef) {
            messagesRef.current.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight });
            });
        }
        setMessagesToShow(messageList)
    }

    return (
        <div className='details-window'>
            <div className='hapto-flex group-text' style={activeTab === 'contacts' ? { justifyContent: 'center' } : {}}>
                <div className='chat-with' >You are chatting with Pod 1</div>
                {activeTab === 'groups' && <Avatar.Group size="small">
                    <Avatar src="https://joeschmoe.io/api/v1/random" />
                    <Avatar src="https://joeschmoe.io/api/v1/random" />
                    <Avatar src="https://joeschmoe.io/api/v1/random" />
                </Avatar.Group>}
            </div>
            <div className='chat-scroller' ref={messagesRef}>
                {messagesToShow?.map((item, index) => {
                    return (
                        <div className='chat-item' style={{ alignItems: item.isSend ? 'flex-end' : 'flex-start' }} key={index}>
                            <Avatar size="small" src="https://joeschmoe.io/api/v1/random" />
                            <div className='message-box'>
                                {item?.image && <img src={item.image} alt="chat" className="chat-image" />}
                                {item.message || ''}
                            </div>
                            <div className='read-by'>read 12:10 PM</div>
                        </div>
                    )
                })}
            </div>
            <div className='details-send-container'>
                <div className='send-container'>
                <SearchOutlined className="search-icon"/>
                    <input placeholder="Search" className='send-input' />
                    <div className='link-add'>
                        <LinkOutlined />
                    </div>
                </div>
                <div className='send-button'>
                    <SendOutlined />
                </div>
            </div>
        </div>
    )
}

export default ChatDetails