import { CloseOutlined } from '@ant-design/icons';
import { Button, DatePicker, Select, TimePicker } from 'antd';
import React, { useState } from 'react';
import CustomInput from '../../../../common/CustomInput';
import './style.scss';

const NewMeeting = (props) => {
    const { close } = props;
    const [meetingName, setMeetingName] = useState(null);
    const [when, setWhen] = useState(null);
    const [time, setTime] = useState(null);
    const [members, setMembers] = useState(null);

    return (
        <div className='new-meeting-container'>
            <div className='hapto-flex'>
                <div className='schedule hapto-text-dark'>Schedule Meeting</div>
                <div className='cancel-text hapto-text-dark' onClick={() => close()}>Cancel</div>
            </div>

            <CustomInput label="Meeting Name" value={meetingName} onChange={setMeetingName} />
            <div className='hapto-flex date-container'>
                <div className='date-style'>
                    <div className='hapto-text-dark'>When</div>
                    <DatePicker style={{ width: '100%' }} label="When" placeholder='Select Date' />
                </div>
                <div className='date-style'>
                    <div className='hapto-text-dark'>Time</div>
                    <TimePicker style={{ width: '100%' }} label="Time" placeholder='Select Time' />
                </div>
            </div>
            <div className='hapto-flex date-container'>
                <div className='date-style'></div>
                <div className='date-style'>
                    <div className='hapto-text-dark'>Assign Pod</div>
                    <Select style={{ width: '100%' }} placeholder='Select Pod' />
                </div>
            </div>
            <div className='add-members'>
                <CustomInput search label="Add Participants" placeholder="Search" value={members} onChange={setMembers} />
                <div className='group-one'>Group 1</div>
            </div>
            <div className='members-list'>
                {[1, 2, 3, 4, 5, 6]?.map(item => {
                    return (
                        <div key={item} className="members-item hapto-text-dark">Hayden S
                            <div className="close-icon">
                                <CloseOutlined />
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className='hapto-action-buttons'>
                <Button className='submit-button' onClick={() => close()}>Schedule Now</Button>
            </div>
        </div>
    )
}

export default NewMeeting