import { Tabs } from 'antd';
import React, { useState } from 'react';
import './styles.scss';

import PersonalImage from '../../assets/images/tabs/allpods.png';
import ProductiveImage from '../../assets/images/tabs/wifi.png';

import PersonalActiveImage from '../../assets/images/tabs/allpods-active.png';
import ProductiveActiveImage from '../../assets/images/tabs/wifi-active.png';
import PersonalAnalytics from './personal';
import ProductivityAnalytics from './productivity';

const { TabPane } = Tabs;

const ReportsAndAnalytics = (props) => {
    const [activeKey, setActiveKey] = useState('1');


    return (
        <div className='reports-and-analytics-container'>
            <Tabs defaultActiveKey={activeKey} activeKey={activeKey} onChange={setActiveKey} tabBarStyle={{ color: "#1D2121" }}>
                <TabPane tab={
                    <span className='tab-name'>
                        {activeKey === '1' && <div className='active-tab' />}
                        <img src={activeKey === '1' ? ProductiveActiveImage : ProductiveImage} alt="allpods" className='icon-style' />
                        Productivity Analytics
                    </span>} key="1">
                    <ProductivityAnalytics />
                </TabPane>
                <TabPane tab={
                    <span className='tab-name'>
                        {activeKey === '2' && <div className='active-tab' />}
                        <img src={activeKey === '2' ? PersonalActiveImage : PersonalImage} alt="boards" className='icon-style' />
                        Personal Analytics
                    </span>} key="2">
                    <PersonalAnalytics />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default ReportsAndAnalytics