import ReduxInitialState from "../../config/initialState";
import { QUICK_ACTIONS_FULL_SCREEN_MEETING_ROOM, QUICK_ACTIONS_MEETING_ROOM, QUICK_ACTIONS_SHOW_CREATE } from "./constants";

const initialState = ReduxInitialState.quickAction

const quickActionReducer = (state = initialState, action) => {
    switch (action.type) {
        case QUICK_ACTIONS_MEETING_ROOM:
            return {
                ...state,
                showMeetingRoom: action.payload
            }
        case QUICK_ACTIONS_FULL_SCREEN_MEETING_ROOM:
            return {
                ...state,
                fullScreenMeetingRoom: action.payload
            }
        case QUICK_ACTIONS_SHOW_CREATE:
            return {
                ...state,
                showCreate: action.payload
            }
        default:
            return state;
    }
};

export default quickActionReducer;
