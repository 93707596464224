import { FilterOutlined } from "@ant-design/icons";
import { Button, Checkbox, Select } from "antd";
import React, { useState } from "react";
import './style.scss';

const TeamProfileFilter = (props) => {
    const { close } = props;
    const [statusActive, setStatusActive] = useState(false);
    const [statusInProgress, setStatusInProgress] = useState(false);
    const [statusOnHold, setStatusOnHold] = useState(false);
    const [typeGeneral, setTypeGeneral] = useState(false);
    const [typeMeeting, setTypeMeeting] = useState(false);
    const [typeReport, setTypeReport] = useState(false);

    return (
        <div className="pods-list-filter-container">
            <div className="hapto-flex filter-head">
                <div className="hapto-flex">
                    <FilterOutlined style={{ marginRight: 5 }} />
                    Filter
                </div>
                <div className="hapto-text-dark cancel" onClick={() => close()}>Cancel</div>
            </div>

            <div className="check-flex">
                <div className="check-item">
                    <div className="hapto-text-dark">Status</div>
                    <div><Checkbox className="check-wrapper" checked={statusActive} onChange={() => setStatusActive(!statusActive)}>Active</Checkbox></div>
                    <div><Checkbox className="check-wrapper" checked={statusInProgress} onChange={() => setStatusInProgress(!statusInProgress)}>In Progress</Checkbox></div>
                    <div><Checkbox className="check-wrapper" checked={statusOnHold} onChange={() => setStatusOnHold(!statusOnHold)}>On Hold</Checkbox></div>
                </div>
                <div className="check-item">
                    <div className="hapto-text-dark">Task Type</div>
                    <div><Checkbox className="check-wrapper" checked={typeGeneral} onChange={() => setTypeGeneral(!typeGeneral)}>General</Checkbox></div>
                    <div><Checkbox className="check-wrapper" checked={typeMeeting} onChange={() => setTypeMeeting(!typeMeeting)}>Meeting</Checkbox></div>
                    <div><Checkbox className="check-wrapper" checked={typeReport} onChange={() => setTypeReport(!typeReport)}>Report</Checkbox></div>
                </div>
            </div>

            <div className="inner-filter-container">
                <div className="hapto-text-dark">Department</div>
                <Select className='select-style' />

                <div className="hapto-text-dark">Member</div>
                <Select className='select-style' />

                <div className='hapto-action-buttons'>
                    <Button className='submit-button' onClick={() => close()}>Save</Button>
                </div>
            </div>
        </div>
    )
}

export default TeamProfileFilter