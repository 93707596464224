import { ExportOutlined, FileImageOutlined, LinkOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import './style.scss';

const NewPost = (props) => {
    const { close } = props;

    return (
        <div className='new-post-container'>
            <div className='hapto-flex'>
                <div className='new-post'>New Post</div>
                <div className='delete-post'>Delete Post</div>
            </div>

            <div className='hapto-text-dark'>Post Description</div>
            <TextArea className='text-area' autoSize />
            <div className='other-links'>
                <div className='link-item'>
                    <LinkOutlined className='link-image' />
                    <div className='link-text'>Link</div>
                </div>
                <div className='link-item'>
                    <FileImageOutlined className='link-image' />
                    <div className='link-text'>Image</div>
                </div>
                <div className='link-item' onClick={() => props.showPollModal(true)}>
                    <ExportOutlined className='link-image' />
                    <div className='link-text'>Poll</div>
                </div>
                <div className='link-item' onClick={() => props.showVideoModal(true)} >
                    <VideoCameraOutlined className='link-image' />
                    <div className='link-text'>Video</div>
                </div>
            </div>

            <div className='hapto-action-buttons'>
                <Button className='submit-button cancel' onClick={() => close()}>Save Draft</Button>
                <Button className='submit-button' onClick={() => close()}>Post Now</Button>
            </div>
        </div>
    )
}

export default NewPost