import { CalendarOutlined, CheckOutlined, FilePdfOutlined, PlusCircleOutlined, RobotOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Timeline } from 'antd';
import React from 'react';
import { OngoingProjectList } from '../data';
import './style.scss';

const ProjectDetails = (props) => {
    const details = OngoingProjectList[0]

    return (
        <div className='projects-details-container'>
            <div className='inner-container'>
                <div className='hapto-flex'>
                    <div />
                    <Button className='filter-button'>Edit Project</Button>
                </div>

                <div className='project-details-flex'>
                    <div className='left-item'>
                        <div className='hapto-flex'>
                            <div className='hapto-flex'>
                                <RobotOutlined />
                                <div className='project-details'>
                                    <div className='project-name'>{details.name}</div>
                                    <div className='priority'>{details.priority} Priority</div>
                                </div>
                            </div>
                            <div>
                                <div className='hapto-text-dark'>Project Owner</div>
                                <div className='profile-container'>
                                    <Avatar size="small" src="https://joeschmoe.io/api/v1/random" />
                                    <div>
                                        <div className='profile-name'>{details.owner}</div>
                                        <div className='profile-position'>{details.owner_position}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='project-desc-margin'>
                            <div className='project-head'>Project Description</div>
                            <div className='project-desc'>{details.desc}</div>
                        </div>

                        <div className='hapto-flex due-margin'>
                            <div>
                                <div className='hapto-flex'>
                                    <div className='project-head'>Started on</div>
                                    <div className='project-desc date-margin'>22/6/2022</div>
                                </div>
                                <div className='hapto-flex'>
                                    <div className='project-head'>Project Due</div>
                                    <div className='project-desc date-margin'>22/9/2022</div>
                                </div>
                            </div>
                            <div className='status'>In Progress</div>
                        </div>

                        <div className='other-section resource-margin'>
                            <div className='upload-head'>Resources</div>
                            <div className='resources-wrapper'>
                                {[1, 2, 3, 4, 5, 6].map(item => {
                                    return (
                                        <div key={item} className='resource-item hapto-flex'>
                                            <div className='hapto-flex'>
                                                <div className='logo-container'>
                                                    <FilePdfOutlined />
                                                </div>
                                                <div>
                                                    <div className='project-head'>Design.xd</div>
                                                    <div className='project-desc'>21 mb</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div className='other-section'>
                            <div className='upload-head'>Upload Files</div>
                            <div className='upload-container'>Drag and Drop files here</div>
                        </div>
                    </div>

                    <div className='right-item'>
                        <div className='total-progress hapto-flex'>
                            <div className='project-desc'>Total Progress</div>
                            <div className='project-desc'>17%</div>
                        </div>

                        <div className='project-desc'>Assigned Pods</div>
                        <div className='pods-list'>
                            <div className='pods-item'>Pods 1</div>
                            <div className='pods-item'>Pods 2</div>
                            <div className='pods-item'>Pods 3</div>
                            <div className='pods-item'>Pods 4</div>
                            <div className='pods-item'>Pods 5</div>
                            <div className='pods-item'>Pods 6</div>
                            <div className='pods-item'>Pods 4</div>
                            <div className='pods-item'>Pods 5</div>
                            <div className='pods-item'>Pods 6</div>

                        </div>

                        <div className='project-desc'>Milestones</div>
                        <div className='milestone-item'>
                            <div className='pointer'><CheckOutlined /></div>
                            <input className="milestone-input" placeholder="Enter your project milestone..." />
                        </div>
                        <div className='milestone-item'>
                            <div className='pointer'></div>
                            <input className="milestone-input" placeholder="Enter your project milestone..." />
                        </div>
                        <div className='milestone-item'>
                            <div className='pointer'></div>
                            <input className="milestone-input" placeholder="Enter your project milestone..." />
                        </div>

                        <div className='project-timeline'>
                            <div className='project-head'>Project Timeline</div>
                            <Timeline mode={'left'}>
                                <Timeline.Item dot={<div className='timeline-icon-background'><CalendarOutlined className='timeline-icon'/></div>} label="22/09/2022">Due date</Timeline.Item>
                                <Timeline.Item dot={<div className='timeline-icon-background'><CalendarOutlined className='timeline-icon'/></div>} label="22/08/2022">Review of website and final checkup</Timeline.Item>
                                <Timeline.Item dot={<div className='timeline-icon-background'><UserOutlined className='timeline-icon'/></div>} label="16/08/2022">2 Members added
                                    <Avatar.Group className='avatar-group'>
                                        <Avatar size="small" src="https://joeschmoe.io/api/v1/random" />
                                        <Avatar size="small" src="https://joeschmoe.io/api/v1/random" />
                                    </Avatar.Group>
                                </Timeline.Item>

                                <Timeline.Item dot={<div className='timeline-icon-background'><UserOutlined className='timeline-icon'/></div>} label="14/08/2022">2 Members added
                                    <Avatar.Group className='avatar-group'>
                                        <Avatar size="small" src="https://joeschmoe.io/api/v1/random" />
                                        <Avatar size="small" src="https://joeschmoe.io/api/v1/random" />
                                    </Avatar.Group>
                                </Timeline.Item>
                            </Timeline>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectDetails