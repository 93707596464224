import { BorderOuterOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, DatePicker, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react';
import { PriorityList, StatusList } from '../../../constants/lov';
import './style.scss';

const NewGoals = (props) => {
    const { close } = props;
    const [showNewObjectiveModal, setShowObjectiveModal] = useState(false);

    return (
        <div className='new-goals-container'>
            <div className='hapto-flex'>
                <div className='hapto-flex'>
                    <BorderOuterOutlined />
                    <div className='hapto-text-dark head-label'>New Goal</div>
                </div>
                <div className='cancel-project' onClick={() => close()}><CloseOutlined /></div>
            </div>

            <div className='text-area-label hapto-text-dark'>Goal Description</div>
            <TextArea className='text-area' autoSize />

            <div className='other-inputs'>
                <div className='other-item'>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Start Date</div>
                        <DatePicker className='select-style' />
                    </div>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Due Date</div>
                        <DatePicker className='select-style' />
                    </div>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Assign Owner</div>
                        <Select className='select-style' />
                    </div>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Assign Pod</div>
                        <Select className='select-style' />
                    </div>
                </div>

                <div className='other-item'>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Priority</div>
                        <Select className='select-style' options={PriorityList}/>
                    </div>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Status</div>
                        <Select className='select-style' options={StatusList}/>
                    </div>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Collaborators</div>
                        <Select className='select-style' />
                    </div>
                </div>
            </div>

            {showNewObjectiveModal && <div className='new-objective-modal'>
                <div className='new-obj-item'>
                    <div className='hapto-text-dark text-area-label'>Objective Description</div>
                    <input className='select-style' />
                </div>
                <div className='new-obj-item'>
                    <div className='hapto-text-dark obj-area-label'>Objective Level</div>
                    <Select className='select-style' />
                </div>
            </div>}
            <div className='text-area-label hapto-text-dark'>Objectives</div>
            <Button className='add-button' onClick={() => setShowObjectiveModal(!showNewObjectiveModal)}>+ Add Objective</Button>

            <div className='hapto-action-buttons'>
                <Button className='submit-button cancel' onClick={() => close()}>Cancel</Button>
                <Button className='submit-button' onClick={() => close()}>Submit</Button>
            </div>
        </div>
    )
}

export default NewGoals