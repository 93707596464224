import { FilterOutlined } from "@ant-design/icons";
import { Button, Checkbox, DatePicker } from "antd";
import React, { useState } from "react";
import './style.scss';

const TeamProfileFilter = (props) => {
    const { close } = props;
    const [statusActive, setStatusActive] = useState(false);
    const [statusOnLeave, setStatusOnLeave] = useState(false);
    const [podAssigned, setPodAssigned] = useState(false);
    const [podUnAssigned, setPodUnAssigned] = useState(false);
    const [accessAdmin, setAccessAdmin] = useState(false);
    const [accessEmployee, setAccessEmployee] = useState(false);

    return (
        <div className="teamprofile-filter-container">
            <div className="hapto-flex filter-head">
                <div className="hapto-flex">
                    <FilterOutlined style={{ marginRight: 5 }} />
                    Filter
                </div>
                <div className="hapto-text-dark cancel" onClick={() => close()}>Cancel</div>
            </div>

            <div className="inner-filter-container">
                <div className="check-flex">
                    <div className="check-item">
                        <div className="hapto-text-dark">Status</div>
                        <div><Checkbox className="check-wrapper" checked={statusActive} onChange={() => setStatusActive(!statusActive)}>Active</Checkbox></div>
                        <div><Checkbox className="check-wrapper" checked={statusOnLeave} onChange={() => setStatusOnLeave(!statusOnLeave)}>On Leave</Checkbox></div></div>
                    <div className="check-item">
                        <div className="hapto-text-dark">Pod Status</div>
                        <div><Checkbox className="check-wrapper" checked={podUnAssigned} onChange={() => setPodUnAssigned(!podUnAssigned)}>Unassigned to Pod</Checkbox></div>
                        <div><Checkbox className="check-wrapper" checked={podAssigned} onChange={() => setPodAssigned(!podUnAssigned)}>Assigned to Pod</Checkbox></div>
                    </div>
                    <div className="check-item">
                        <div className="hapto-text-dark">Access</div>
                        <div><Checkbox className="check-wrapper" checked={accessAdmin} onChange={() => setAccessAdmin(!accessAdmin)}>Admin</Checkbox></div>
                        <div><Checkbox className="check-wrapper" checked={accessEmployee} onChange={() => setAccessEmployee(!accessEmployee)}>Employee</Checkbox></div>
                    </div>
                </div>

                <div className="hapto-flex filters-margin">
                    <div className="hapto-flex">
                        <div className="hapto-text-dark">Department</div>
                        <input className='select-style' />
                    </div>
                    <div className="hapto-flex">
                        <div className="hapto-text-dark">Joining Year</div>
                        <DatePicker className='select-style' />
                    </div>
                </div>
                <div className="hapto-flex filters-margin">
                    <div className="details-item">Development, Design, Purchasing</div>
                    <div className="details-item">2017</div>
                </div>
                <div className="hapto-flex filters-margin">
                    <div className="hapto-flex">
                        <div className="hapto-text-dark">Name</div>
                        <input className='select-style' />
                    </div>
                    <div className="hapto-flex">
                        <div className="hapto-text-dark">Employee Id</div>
                        <DatePicker className='select-style' />
                    </div>
                </div>
                <div className="hapto-flex filters-margin">
                    <div className="details-item">Development, Design, Purchasing</div>
                    <div className="details-item">A12</div>
                </div>

                <div className='hapto-action-buttons'>
                    <Button className='submit-button' onClick={() => close()}>Save</Button>
                </div>
            </div>

        </div>
    )
}

export default TeamProfileFilter