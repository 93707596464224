import { PlusCircleFilled, SearchOutlined } from "@ant-design/icons";
import { Avatar, Button, Table } from "antd";
import React, { useState } from "react";
import { EmployeesData } from "./data";
import './styles.scss';
import CustomModal from '../../../common/CustomModal';
import NewTeamMember from "../teamprofile/newteammember";
import BulkTeamMembers from "../teamprofile/bulkteam";

const Employees = (props) => {
    const [searchText, setSearchText] = useState(null);
    const [showDepartmentModal, setShowDepartmentModal] = useState(false);
    const [showNewTeamMemberModal, setShowNewTeamMemberModal] = useState(false);
    const [showBulkTeamModal, setShowBulkTeamModal] = useState(false)

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: {
                compare: (a, b) => a.name - b.name,
            },
            render: (text, record) => (
                <div className="profile-column-flex">
                    <Avatar size="medium" src="https://joeschmoe.io/api/v1/random" />
                    <div className="profile-desc">
                        <div className="hapto-text-dark">{text}</div>
                        <div className="hapto-text-light">{record?.role}</div>
                    </div>
                </div>
            )
        },
        {
            title: 'Emp Id',
            dataIndex: 'empid',
            sorter: {
                compare: (a, b) => a.empid - b.empid,
            },
            render: (text, record) => (
                <div className="hapto-text-dark">{record?.empid}</div>
            )
        },
        {
            title: 'Access',
            dataIndex: 'access',
            sorter: {
                compare: (a, b) => a.access - b.access,
            },
            render: (text, record) => (
                <div className="hapto-text-dark">{record?.access}</div>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            sorter: {
                compare: (a, b) => a.status - b.status,
            },
            render: (text, record) => (
                <div className="hapto-text-dark">{record?.status}</div>
            )
        },
        {
            title: 'Pod Status',
            dataIndex: 'podstatus',
            sorter: {
                compare: (a, b) => a.podstatus - b.podstatus,
            },
            render: (text, record) => (
                <div className="hapto-text-dark">{record?.podstatus}</div>
            )
        },
        {
            title: 'Department',
            dataIndex: 'department',
            sorter: {
                compare: (a, b) => a.department - b.department,
            },
            render: (text, record) => (
                <div className="hapto-text-dark">{record?.department}</div>
            )
        },
        {
            title: 'Joining Date',
            dataIndex: 'joiningdate',
            sorter: {
                compare: (a, b) => a.joiningdate - b.joiningdate,
            },
            render: (text, record) => (
                <div className="hapto-text-dark">{record?.joiningdate}</div>
            )
        },
    ];

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    const renderDepartmentModal = () => {
        return (
            <div className="new-department-modal">
                <div className="hapto-flex create-style">
                    Create Department
                    <div className="hapto-text-dark cancel" onClick={() => setShowDepartmentModal(false)}>Cancel</div>
                </div>

                <div className="hapto-text-dark">Department Name</div>
                <input className='select-style' />

                <div className='hapto-action-buttons'>
                    <Button className='submit-button' onClick={() => setShowDepartmentModal(false)}>Save</Button>
                </div>
            </div>
        )
    }

    return (
        <div className='employees-container'>
            {showNewTeamMemberModal && <CustomModal close={() => setShowNewTeamMemberModal(false)}>
                <NewTeamMember close={() => setShowNewTeamMemberModal(false)} />
            </CustomModal>}
            {showDepartmentModal && <CustomModal close={() => setShowDepartmentModal(false)}>
                {renderDepartmentModal()}
            </CustomModal>}
            {showBulkTeamModal && <CustomModal close={() => setShowBulkTeamModal(false)}>
                <BulkTeamMembers close={() => setShowBulkTeamModal(false)} />
            </CustomModal>}

            <div className="search-filter hapto-flex">
                <div className="search-image"><SearchOutlined /></div>
                <input className="search-input" value={searchText} onChange={(event) => setSearchText(event.target.value)} placeholder="Search Team Member" />
                <div className="actions-wrapper">
                    <Button className='create-button' onClick={() => setShowBulkTeamModal(true)}>Add Bulk</Button>
                    <Button className='create-button' onClick={() => setShowDepartmentModal(true)}>Create Department</Button>
                    <Button className='create-button' onClick={() => setShowNewTeamMemberModal(true)}><PlusCircleFilled />Add Employee</Button>
                </div>
            </div>

            <div className="hapto-text-dark">Filter</div>
            <div className="filtered-list">
                <div className="hapto-text-light filtered-item">Design</div>
                <div className="hapto-text-light filtered-item" style={{ backgroundColor: "#5433FF", color: "#ffffff" }}>Devops</div>
                <div className="hapto-text-light filtered-item">Finance</div>
                <div className="hapto-text-light filtered-item">Utilities</div>
                <div className="hapto-text-light filtered-item">Utilities</div>
            </div>

            <Table showHeader={false} columns={columns} dataSource={EmployeesData} onChange={onChange} />
        </div>
    )
}

export default Employees