import { DeleteOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import ConfirmationModal from "../../../common/Confirmation";
import CustomModal from "../../../common/CustomModal";
import EditTasks from "../edittask";
import { MyTasksCompletedListData, MyTasksListData } from "./data";
import './style.scss';

const MyTasksList = (props) => {
    const [showEditModal, setShowEditModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showStatusConfirmModal, setShowStatusConfirmModal] = useState(false);
    const [completedList, setCompletedList] = useState(MyTasksCompletedListData);
    const [listData, setListData] = useState(MyTasksListData)

    const getPriorityColor = (priority) => {
        let backgroundColor = '#FED764'
        if (priority === 'Low') backgroundColor = '#BFB1F9'
        else if (priority === 'Medium') backgroundColor = '#4C9BEB'
        return { backgroundColor }
    }

    const handleDragStart = (event, item) => {
        event.dataTransfer.setData('text/plain', JSON.stringify(item))
    }

    const handleDragOver = (event) => {
        event.preventDefault();
    }

    const handleDrop = (dropValue) => {
        console.log(dropValue)
        if (dropValue) {
            const destinationData = JSON.parse(dropValue)
            let destinationList = completedList;

            const isDuplicate = destinationList?.find(elem => elem.id === destinationData?.id)
            if (!isDuplicate) {
                destinationList?.unshift({
                    ...destinationData
                })

                let updatedOriginList = listData;
                updatedOriginList = updatedOriginList?.filter(elem => elem?.id !== destinationData?.id)
                console.log(updatedOriginList, destinationData?.id)

                setListData(updatedOriginList)
            }
            setCompletedList(destinationList)
        }
    }

    return (
        <div className="my-tasks-lists-container">
            {showEditModal && <CustomModal close={() => setShowEditModal(false)}>
                <EditTasks close={() => setShowEditModal(false)} />
            </CustomModal>}
            {showConfirmModal && <CustomModal close={() => setShowConfirmModal(false)}>
                <ConfirmationModal label="Are you sure you want to delete Task?" status="Delete Task" action="Delete Task details" close={() => setShowConfirmModal(false)} closeAll={() => { setShowConfirmModal(false) }} />
            </CustomModal>}
            {showStatusConfirmModal !== false && <CustomModal close={() => setShowStatusConfirmModal(false)}>
                <ConfirmationModal
                    success
                    change
                    label="Congratulations, The Task is Complete"
                    status="Completed"
                    action="Change the status of the task to Completed"
                    addtionalMessage="If you do not want to complete the task just yet, you can change the status and click continue and get back to your tasks"
                    close={() => setShowStatusConfirmModal(false)}
                    closeAll={() => { setShowStatusConfirmModal(false); handleDrop(showStatusConfirmModal) }} />
            </CustomModal>}
            <div className="head-row">
                <div className="head-desc name-width">Task Description</div>
                <div className="head-desc other-width">Due Date</div>
                <div className="head-desc other-width">Task Type</div>
                <div className="head-desc other-width">Department</div>
                <div className="head-desc other-width">Priority</div>
                <div className="head-desc other-width">Status</div>
            </div>
            {listData?.map((item, index) => {
                return (
                    <div
                        draggable
                        onDragStart={(e) => handleDragStart(e, item)}
                        onDragOver={(e) => handleDragOver(e)}
                        key={index}
                        className="row-item"
                        onClick={() => setShowEditModal(true)}>
                        <div className="item-desc name-width">{item?.desc}</div>
                        <div className="item-desc other-width">{item?.due}</div>
                        <div className="item-desc other-width">{item?.type}</div>
                        <div className="item-desc other-width">{item?.department}</div>
                        <div className="item-desc priority-width">
                            <div className="priority-color" style={getPriorityColor(item.priority)} />
                            {item?.priority}
                        </div>
                        <div className="item-desc other-width">{item?.status}</div>
                        <div className="delete-icon" onClick={(e) => { e.stopPropagation(); setShowConfirmModal(true) }}>
                            <DeleteOutlined className="icon-color" />
                        </div>
                    </div>
                )
            })}

            <div>Completed Tasks</div>
            <div className="head-row">
                <div className="head-desc name-width">Task Description</div>
                <div className="head-desc other-width">Completed On</div>
                <div className="head-desc other-width">Task Type</div>
                <div className="head-desc other-width">Department</div>
                <div className="head-desc other-width">Priority</div>
                <div className="head-desc other-width">Status</div>
            </div>
            <div onDrop={(e) => setShowStatusConfirmModal(e.dataTransfer.getData("text/plain"))} onDragOver={(e) => handleDragOver(e)}>
                {completedList?.map((item, index) => {
                    return (
                        <div key={index} className="row-item completed-row">
                            <div className="item-desc name-width">{item?.desc}</div>
                            <div className="item-desc other-width">{item?.due}</div>
                            <div className="item-desc other-width">{item?.type}</div>
                            <div className="item-desc other-width">{item?.department}</div>
                            <div className="item-desc priority-width">
                                <div className="priority-color" style={getPriorityColor(item.priority)} />
                                {item?.priority}
                            </div>
                            <div className="item-desc other-width">{item?.status}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default MyTasksList