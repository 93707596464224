import React from 'react';
import './styles.scss';
import SmileIcon from '../../../assets/images/reports/smile.png';
import ProfileImage from '../../../assets/images/reports/profile.jpeg';
import BadgeIcon from '../../../assets/images/reports/badge.png';
import ProfitImage from '../../../assets/images/reports/profit.png';
import LossImage from '../../../assets/images/reports/loss.png';
import ColumnChart from '../../reports/charts/column';
import { Progress, Select } from 'antd';
import MultiLineChart from '../../reports/charts/multiline';
import { TinyLine } from '@ant-design/charts';

const PersonalAnalytics = (props) => {
    const data = [
        264, 417, 438, 887, 309, 397, 550, 575, 563, 430, 525, 592, 492, 467, 513, 546, 983, 340, 539, 243, 226, 192,
    ];

    const config = {
        height: 40,
        width: '100%',
        autoFit: true,
        data,
        smooth: true,
        tooltip: false,
        color: '#FF9191'
    };

    return (
        <div className='personal-analytics-container'>
            <div className='hapto-flex'>
                <div className='top-left-container card-style'>
                    <div className='review-container'>
                        <div className='review-top-section'>
                            <div className='smiley-container'>
                                <img src={SmileIcon} className="smile-fit" alt="name" />
                            </div>
                            <div>
                                <div className='percentage'>61%</div>
                                <div className='hapto-text-dark'>Moderate Satisfaction</div>
                                <div className='hapto-text-light'>Review</div>
                            </div>
                        </div>
                        <div className='review-progress'>
                            <div className='review-progress-item'>
                                <div className='hapto-text-dark'>Work Satisfaction</div>
                                <div className='progress-bar'><div className='inner' style={{ width: '30%' }} /></div>
                            </div>
                            <div className='review-progress-item'>
                                <div className='hapto-text-dark'>Engagement</div>
                                <div className='progress-bar'><div className='inner' style={{ width: '60%' }} /></div>
                            </div>
                            <div className='review-progress-item'>
                                <div className='hapto-text-dark'>Mood</div>
                                <div className='progress-bar'><div className='inner' style={{ width: '50%' }} /></div>
                            </div>
                            <div className='review-progress-item'>
                                <div className='hapto-text-dark'>Productivity</div>
                                <div className='progress-bar'><div className='inner' style={{ width: '80%' }} /></div>
                            </div>
                            <div className='review-progress-item'>
                                <div className='hapto-text-dark'>Health</div>
                                <div className='progress-bar'><div className='inner' style={{ width: '90%' }} /></div>
                            </div>
                            <div className='review-progress-item'>
                                <div className='hapto-text-dark'>Stress</div>
                                <div className='progress-bar'><div className='inner' style={{ width: '40%' }} /></div>
                            </div>
                        </div>
                    </div>

                    <div className='personal-productivity-container'>
                        <div className='info-container'>
                            <div className='left-container'>
                                <div className='profile-image-wrapper'>
                                    <img src={ProfileImage} className="profile-fit" alt="profile" />
                                </div>
                                <div>
                                    <div className='name'>Holden Mcgroin</div>
                                    <div className='hapto-text-dark'>Software Developer</div>
                                    <div className='assigned'>Assigned Pods</div>
                                    <div className='pod-list'>
                                        <div className='pod-item' style={{ color: '#5433FF' }}>Pod 1</div>
                                        <div className='pod-item' style={{ color: '#5433FF' }}>Pod 2</div>
                                        <div className='pod-item' style={{ color: '#5433FF' }}>Pod 3</div>
                                        <div className='pod-item'>Pod 4</div>
                                        <div className='pod-item'>Pod 5</div>
                                        <div className='pod-item'>Pod 6</div>
                                    </div>
                                </div>
                            </div>

                            <div className='right-container'>
                                <div className='level-head'>Level 10 Achiever</div>
                                <div className='count'>
                                    10 <img src={BadgeIcon} className="badge-fit" alt="badge" />
                                </div>
                                <div className='hapto-text-dark'>Commendations Received</div>
                            </div>
                        </div>
                        <div className='column-chart-height'>
                            <ColumnChart />
                        </div>
                        <div className='head-container'>Prodcutivity</div>
                    </div>
                </div>

                <div className='top-right-container'>
                    <div className='right-card-style'>
                        <div className='hapto-text-dark'>View Skill Matrix</div>
                        <div className='hapto-flex'>
                            <div className='hapto-flex-inner-item'>
                                <div className='hapto-text-dark pod-label'>By Pod</div>
                                <Select className='select-style' placeholder="Pod" />
                            </div>
                            <div className='hapto-flex-inner-item'>
                                <div className='hapto-text-dark pod-label'>By Dept</div>
                                <Select className='select-style' placeholder="Department" />
                            </div>
                        </div>
                    </div>
                    <div className='multi-chart-container'>
                        <MultiLineChart />
                        <div className='head-container'>Engagement</div>
                    </div>
                </div>
            </div>


            <div className='hapto-flex' style={{ marginTop: 20 }}>
                <div className='small-group card-style'>
                    <div className='small-head-container'>Productivity</div>
                    <div className='hapto-text-dark' style={{ fontSize: 18 }}>47%</div>
                    <div className='hapto-flex profit-text'>
                        <img src={ProfitImage} className="profit-image" alt="profit" />
                        +8%
                    </div>

                    <div className='profit-chart'>
                        <TinyLine {...{ ...config, color: '#43CE33' }} />
                    </div>
                </div>
                <div className='small-group card-style'>
                    <div className='small-head-container'>Engagement</div>
                    <div className='hapto-text-dark' style={{ fontSize: 18 }}>19%</div>
                    <div className='hapto-flex profit-text loss'>
                        <img src={LossImage} className="profit-image" alt="profit" />
                        -11%
                    </div>

                    <div className='profit-chart'>
                        <TinyLine {...config} />
                    </div>
                </div>

                <div className='large-group card-style'>
                    <div className='large-item'>
                        <div className='progress-container'>
                            <Progress type="circle" percent={86} strokeColor="#5433FF" strokeWidth={11} trailColor="transparent" />
                        </div>
                        <div className='large-head-container'>Average Productivity</div>
                    </div>
                    <div className='large-item'>
                        <div className='progress-container'>
                            <Progress type="circle" percent={66} strokeColor="#5433FF" strokeWidth={11} trailColor="transparent" />
                        </div>
                        <div className='large-head-container'>Average Workplace Satisfaction</div>
                    </div>
                    <div className='large-item'>
                        <div className='progress-container'>
                            <Progress type="circle" percent={76} strokeColor="#5433FF" strokeWidth={11} trailColor="transparent" />
                        </div>
                        <div className='large-head-container'>Average Mood Score</div>
                    </div>
                    <div className='middle-border' />
                </div>
            </div>
        </div>
    )
}

export default PersonalAnalytics