export const ToDoList = [
    {
        type: 'Design',
        name: 'Design for to do list in item 1 in column 1',
        priority: 'High',
        due: 'in 4 Days',
        id: 1,
        status: 'todo'
    },
    {
        type: 'Design',
        name: 'Design for to do list in item 2 in column 1',
        priority: 'High',
        due: 'in 4 Days',
        id: 2,
        status: 'todo'
    },
    {
        type: 'Design',
        name: 'Design for to do list in item 3 in column 1',
        priority: 'High',
        due: 'in 4 Days',
        id: 3,
        status: 'todo'
    },
    {
        type: 'Design',
        name: 'Design for to do list in item 4 in column 1',
        priority: 'High',
        due: 'in 4 Days',
        id: 4,
        status: 'todo'
    },
]

export const InProgressList = [
    {
        type: 'Design',
        name: 'Design for in progress list in item 1 in column 2',
        priority: 'High',
        due: 'in 4 Days',
        id: 5,
        status: 'progress',
        progress: 50
    },
    {
        type: 'Design',
        name: 'Design for in progress list in item 2 in column 2',
        priority: 'High',
        due: 'in 4 Days',
        id: 6,
        status: 'progress',
        progress: 47
    },
    {
        type: 'Design',
        name: 'Design for in progress list in item 3 in column 2',
        priority: 'High',
        due: 'in 4 Days',
        id: 7,
        status: 'progress',
        progress: 23
    }
]

export const ReviewList = [
    {
        type: 'Design',
        name: 'Design for review list in item 1 in column 3',
        priority: 'High',
        due: 'in 4 Days',
        id: 8,
        status: 'review',
        progress: 89
    },
    {
        type: 'Design',
        name: 'Design for review list in item 2 in column 3',
        priority: 'High',
        due: 'in 4 Days',
        id: 9,
        status: 'review',
        progress: 76
    },
    {
        type: 'Design',
        name: 'Design for review list in item 3 in column 3',
        priority: 'High',
        due: 'in 4 Days',
        id: 10,
        status: 'review',
        progress: 62
    }
]

export const CompletedList = [
    {
        type: 'Design',
        name: 'Design for completed list in item 1 in column 4',
        priority: 'High',
        due: 'in 4 Days',
        id: 11,
        status: 'completed'
    },
    {
        type: 'Design',
        name: 'Design for completed list in item 2 in column 4',
        priority: 'High',
        due: 'in 4 Days',
        id: 12,
        status: 'completed'
    }
]