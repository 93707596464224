import { Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import './style.scss';

const HelpCenter = (props) => {

    return (
        <div className="help-center-container">
            <div className="hapto-text-dark">Report a Bug or Issue</div>
            <TextArea className="text-area" rows={6} />
            <div className="submit-wrapper">
                <Button className='submit-button'>Submit</Button>
            </div>

            <div className="hapto-text-dark">Unresolved Reports</div>
            <div className="reports-head">
                <div className="head-item first hapto-text-dark">Description</div>
                <div className="head-item hapto-text-dark">Reported On</div>
                <div className="head-item hapto-text-dark">Status</div>
                <div className="head-item hapto-text-dark">Estimated Resolution</div>
            </div>
            <div className="reports-body">
                <div className="head-item first hapto-text-dark">Issue with Profile editing and edit profile picture</div>
                <div className="head-item hapto-text-dark">12/2/2021</div>
                <div className="head-item hapto-text-dark">In Progress</div>
                <div className="head-item hapto-text-dark">16/2/2021</div>
            </div>
            <div className="reports-body">
                <div className="head-item first hapto-text-dark">Issue with Profile editing</div>
                <div className="head-item hapto-text-dark">12/2/2021</div>
                <div className="head-item hapto-text-dark">In Progress</div>
                <div className="head-item hapto-text-dark">16/2/2021</div>
            </div>
            <div className="reports-body">
                <div className="head-item first hapto-text-dark">Issue with Profile editing</div>
                <div className="head-item hapto-text-dark">12/2/2021</div>
                <div className="head-item hapto-text-dark">In Progress</div>
                <div className="head-item hapto-text-dark">16/2/2021</div>
            </div>
        </div>
    )
}

export default HelpCenter