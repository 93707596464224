import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import React, { useState } from "react";
import CustomInput from "../../../common/CustomInput";
import './style.scss';

const MyProfile = (props) => {
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [role, setRole] = useState(null);
    const [department, setDepartment] = useState(null);
    const [zipCode, setZipCode] = useState(null);
    const [city, setCity] = useState(null);
    const [country, setCountry] = useState(null);
    const [employeeId, setEmployeeId] = useState(null);
    const [access, setAccess] = useState(null);

    return (
        <div className='my-profile-flex'>
            <div>
                <div className='profile-pic'>
                    <Avatar className='profile-fit' src="https://joeschmoe.io/api/v1/random" />
                </div>
                <div className='action-wrapper'>
                    <div className='action-item'><EditOutlined /></div>
                    <div className='action-item'><DeleteOutlined /></div>
                </div>
            </div>

            <div>
                <div className='profile-section'>
                    <div className='hapto-text-dark section-name'>Personal Information</div>
                    <div className='section-flex'>
                        <CustomInput half label="First Name" value={firstName} onChange={setFirstName} />
                        <CustomInput half label="Last Name" value={lastName} onChange={setLastName} />
                        <CustomInput half label="Email Address" value={email} onChange={setEmail} />
                        <CustomInput half label="Phone Number" value={phone} onChange={setPhone} />
                    </div>
                </div>

                <div className='profile-section'>
                    <div className='hapto-text-dark section-name'>Company Information</div>
                    <div className='section-flex'>
                        <CustomInput half label="Company Name" value={companyName} onChange={setCompanyName} />
                        <CustomInput half label="Role" value={role} onChange={setRole} />
                        <CustomInput half label="Department" value={department} onChange={setDepartment} />
                        <CustomInput half label="Zip Code" value={zipCode} onChange={setZipCode} />
                        <CustomInput half label="City" value={city} onChange={setCity} />
                        <CustomInput half label="Country" value={country} onChange={setCountry} />
                        <CustomInput half label="Employee Id" value={employeeId} onChange={setEmployeeId} />
                        <CustomInput half label="Access" value={access} onChange={setAccess} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyProfile