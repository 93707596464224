import { CloseOutlined, PlusOutlined, UsergroupDeleteOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import './style.scss';
import '../pods/feed/newmeeting/style.scss';
import '../pods/feed/newevent/style.scss';
import CustomInput from '../../common/CustomInput';
import { Avatar, Button, DatePicker, TimePicker } from 'antd';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import LocationIcon from '../../assets/images/modals/location.png'

const QuickCreate = (props) => {
    const [meetingName, setMeetingName] = useState(null);
    const [when, setWhen] = useState(null);
    const [time, setTime] = useState(null);
    const [members, setMembers] = useState(null);

    return (
        <div className='create-something-container'>
            <div className='close-icon' onClick={() => props.handleCreate(false)}><CloseOutlined /></div>

            <div className='split-create-screen hapto-flex'>
                <div className='split-create-item'>
                    <div className='new-meeting-container'>
                        <div className='hapto-flex'>
                            <div className='create-header-name hapto-text-dark'>Meetings</div>
                        </div>

                        <CustomInput label="Meeting Name" value={meetingName} onChange={setMeetingName} />
                        <div className='hapto-flex date-container'>
                            <div className='date-style'>
                                <div className='hapto-text-dark'>When</div>
                                <DatePicker style={{ width: '100%' }} label="When" placeholder='Select Date' />
                            </div>
                            <div className='date-style'>
                                <div className='hapto-text-dark'>Time</div>
                                <TimePicker style={{ width: '100%' }} label="Time" placeholder='Select Time' />
                            </div>
                        </div>
                        <CustomInput label="Add Members" placeholder="Search" value={members} onChange={setMembers} />
                        <div className='members-list'>
                            {[1, 2, 3, 4, 5, 6]?.map(item => {
                                return (
                                    <div key={item} className="members-item hapto-text-dark">Hayden S
                                        <div className="close-icon">
                                            <CloseOutlined />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        <div className='hapto-action-buttons'>
                            <Button className='submit-button cancel' onClick={() => props.handleCreate(false)}>Cancel</Button>
                            <Button className='submit-button' onClick={() => props.handleCreate(false)}>Schedule Now</Button>
                        </div>
                    </div>

                    <div className='upcoming-sections'>
                        <div className='create-header-name hapto-text-dark'>Upcoming Meetings</div>
                        <div className='upcoming-list'>
                            {[1, 2, 3].map(item => {
                                return (
                                    <div className='upcoming-item hapto-flex' key={item}>
                                        <div>
                                            <div className="hapto-text-dark">Morning Briefing</div>
                                            <div className="hapto-text-light">at 10:00 AM IST</div>
                                        </div>
                                        <div className='hapto-text-dark join-now'>Join Now</div>
                                    </div>
                                )
                            })}
                        </div>

                        <div className='hapto-action-buttons'>
                            <Button className='submit-button cancel' onClick={() => props.handleCreate(false)}>Cancel</Button>
                            <Button className='submit-button' onClick={() => props.handleCreate(false)}>Save Changes</Button>
                        </div>
                    </div>
                </div>

                <div className='split-create-item no-border'>
                    <div className='new-event-container'>
                        <div className='hapto-flex'>
                            <div className='hapto-flex'>
                                <div className='create-header-name hapto-text-dark'>Event</div>
                            </div>
                        </div>

                        <input placeholder="Event Name" className='name-area' />

                        <div className='hapto-flex date-time-wrapper'>
                            <div className='hapto-flex date-time-item'>
                                <div className='hapto-text-dark'>Date</div>
                                <DatePicker className='select-style' />
                            </div>
                            <div className='hapto-flex date-time-wrapper'>
                                <div className='hapto-text-dark'>Time</div>
                                <TimePicker className='select-style' placeholder='Select Time' defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} />
                            </div>
                        </div>

                        <div className='hapto-flex'>
                        <img src={LocationIcon} alt="location" className="location-icon"/>
                            <input placeholder="Add Location" className='input-area' />
                        </div>

                        <div className='hapto-flex user-details'>
                            <div className='hapto-flex'>
                                <UsergroupDeleteOutlined className='user-avatar icon-color' />
                                <Avatar className='user-avatar' size="small" src="https://joeschmoe.io/api/v1/random" />
                                <Avatar className='user-avatar' size="small" src="https://joeschmoe.io/api/v1/random" />
                                <Avatar className='user-avatar' size="small" src="https://joeschmoe.io/api/v1/random" />
                                <div className='more-avatar'><PlusOutlined /></div>
                            </div>
                            <div className='hapto-flex'>
                                <div className='hapto-text-dark pod-label'>Pod Id</div>
                                <input placeholder="Pod Id" className='input-area' />
                            </div>
                        </div>

                        <div className='text-area-label hapto-text-dark'>Event Description</div>
                        <TextArea className='text-area' autoSize />

                        <div className='hapto-action-buttons'>
                            <Button className='submit-button cancel' onClick={() => props.handleCreate(false)}>Cancel</Button>
                            <Button className='submit-button' onClick={() => props.handleCreate(false)}>Schedule Now</Button>
                        </div>
                    </div>

                    <div className='upcoming-sections'>
                        <div className='create-header-name hapto-text-dark'>Upcoming Events</div>
                        <div className='upcoming-list'>
                            {[1, 2, 3].map(item => {
                                return (
                                    <div className='upcoming-item hapto-flex' key={item}>
                                        <div>
                                            <div className="hapto-text-dark">Office Get Together</div>
                                            <div className="hapto-text-light">at 10:00 AM IST</div>
                                        </div>
                                        <div className='hapto-text-dark join-now'>RSVP</div>
                                    </div>
                                )
                            })}
                        </div>

                        <div className='hapto-action-buttons'>
                            <Button className='submit-button cancel' onClick={() => props.handleCreate(false)}>Cancel</Button>
                            <Button className='submit-button' onClick={() => props.handleCreate(false)}>Save Changes</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuickCreate