export const PodsFeedData = [
    {
        name: 'John Doe',
        desc: 'has completed the Task 1',
        time: '2hrs ago',
        likes: 5,
        commendations: 2,
        comments: [
            {
                message: 'Nice start, I will take over from here on',
                person: 'Lisa S'
            },
            {
                message: 'I will be providing support on that',
                person: 'Cathy Y'
            }
        ]
    },
    {
        name: 'John Doe',
        desc: 'has completed the Task 1',
        time: '2hrs ago',
        likes: 5,
        commendations: 1,
        comments: []
    },
    {
        name: 'John Doe',
        desc: 'has completed the Task 1',
        time: '2hrs ago',
        likes: 5,
        commendations: 0,
        comments: []
    }
]