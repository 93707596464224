import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import React, { useState } from 'react';
import Board from './board';
import MyTasksList from './list';
import './style.scss';
import CustomModal from '../../common/CustomModal'
import CreateTask from './createtask';
import GanttScreen from './gantt';

import ListImage from '../../assets/images/tabs/list.png';
import BoardsImage from '../../assets/images/tabs/boards.png';
import GanntImage from '../../assets/images/tabs/gantt.png';

import ListActiveImage from '../../assets/images/tabs/list-active.png';
import BoardsActiveImage from '../../assets/images/tabs/boards-active.png';
import GanntActiveImage from '../../assets/images/tabs/gantt-active.png';

const { TabPane } = Tabs;

const MyTasks = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [activeKey, setActiveKey] = useState('1');

    const operations = <Button className='create-button' onClick={() => setShowModal(true)}><PlusCircleOutlined />Create Task</Button>;

    return (
        <div className='my-tasks-container'>
            {showModal && <CustomModal close={() => setShowModal(false)}>
                <CreateTask close={() => setShowModal(false)} />
            </CustomModal>}
            <Tabs defaultActiveKey={activeKey} activeKey={activeKey} onChange={setActiveKey} tabBarStyle={{ color: "#1D2121" }} tabBarExtraContent={operations}>
                <TabPane tab={
                    <span className='tab-name'>
                        {activeKey === '1' && <div className='active-tab' />}
                        <img src={activeKey === '1' ? ListActiveImage : ListImage} alt="allpods" className='icon-style' />
                        Task
                    </span>} key="1">
                    <MyTasksList />
                </TabPane>
                <TabPane tab={
                    <span className='tab-name'>
                        {activeKey === '2' && <div className='active-tab' />}
                        <img src={activeKey === '2' ? BoardsActiveImage : BoardsImage} alt="boards" className='icon-style' />
                        Boards
                    </span>} key="2">
                    <Board />
                </TabPane>
                <TabPane tab={
                    <span className='tab-name'>
                        {activeKey === '3' && <div className='active-tab' />}
                        <img src={activeKey === '3' ? GanntActiveImage : GanntImage} alt="gantt" className='icon-style' />
                        Gantt
                    </span>} key="3">
                    <GanttScreen />
                </TabPane>

            </Tabs>
        </div>
    )
}

export default MyTasks