import { MoreOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import React, { useState } from 'react';
import { CompletedList, InProgressList, ReviewList, ToDoList } from './data';
import './style.scss';

const Board = (props) => {
    const [todoList, setTodoList] = useState(ToDoList);
    const [progressList, setProgressList] = useState(InProgressList);
    const [reviewList, setReviewList] = useState(ReviewList);
    const [completedList, setCompletedList] = useState(CompletedList)

    const getBoardColor = (type) => {
        if (type === 'todo') return '#8833FF'
        else if (type === 'progress') return '#33BFFF'
        else if (type === 'review') return '#FFCB33'
        else return '#29CC39'
    }

    const getListForStatus = (status) => { return status === 'todo' ? todoList : status === 'progress' ? progressList : status === 'review' ? reviewList : completedList }

    const configureListForStatus = (status, data) => {
        if (status === 'todo') setTodoList(data)
        else if (status === 'progress') setProgressList(data)
        else if (status === 'review') setReviewList(data)
        else setCompletedList(data)
    }


    const handleDragStart = (event, item) => {
        event.dataTransfer.setData('text/plain', JSON.stringify(item))
    }

    const handleDragOver = (event) => {
        event.preventDefault();
    }

    const handleDrop = (event, status) => {
        let dropValue = event.dataTransfer.getData("text/plain");
        if (dropValue) {
            const destinationData = JSON.parse(dropValue)
            let destinationList = getListForStatus(status);

            const isDuplicate = destinationList?.find(elem => elem.id === destinationData?.id)
            if (!isDuplicate) {
                destinationList?.unshift({
                    ...destinationData,
                    status
                })

                let updatedOriginList = getListForStatus(destinationData?.status);
                updatedOriginList = updatedOriginList?.filter(elem => elem?.id !== destinationData?.id)
                configureListForStatus(destinationData?.status, updatedOriginList)
            }
            configureListForStatus(status, destinationList)
        }
    }

    const renderBoardColumn = (columnKey) => {
        const listData = getListForStatus(columnKey);

        const moreContent = (
            <div className='popover-more-content'>
                <div className='hapto-text-dark content-item'>Edit</div>
                <div className='hapto-text-dark content-item delete'>Delete</div>
            </div>
        );

        return <div className='board-cards-list' onDrop={(e) => handleDrop(e, columnKey)} onDragOver={(e) => handleDragOver(e)}>
            {listData?.map((todo, todoIndex) => {
                const color = getBoardColor(todo?.status)
                return (
                    <div
                        className='board-card'
                        draggable
                        onDragStart={(e) => handleDragStart(e, todo)}
                        onDragOver={(e) => handleDragOver(e)}
                        key={todoIndex}>
                       <div className='board-flex'>
                            <div className='board-type'><div className='dot' style={{ backgroundColor: color }}></div>{todo?.type}</div>
                            <Popover content={moreContent} placement="rightTop" trigger="click">
                                <div className="add-icon"><MoreOutlined /></div>
                            </Popover>
                        </div>
                        <div className='board-name'>{todo?.name}</div>

                        <div className='board-flex'>
                            <div className='board-priority' style={{ backgroundColor: color }}>{todo?.priority} Priority</div>
                            <div className='due'>Due in {todo?.due}</div>
                        </div>
                    </div>
                )
            })}
        </div>
    }

    return (
        <div className='board-container'>
            <div className='board-cards'>
                <div className='board-item'>
                    <div className='board-head'>
                        <div className='border-border' style={{ backgroundColor: getBoardColor('todo') }}></div>
                        <div className='head-text'>TO DO</div>
                    </div>
                    {renderBoardColumn('todo')}
                </div>
            </div>
            <div className='board-cards'>
                <div className='board-item'>
                    <div className='board-head'>
                        <div className='border-border' style={{ backgroundColor: getBoardColor('progress') }}></div>
                        <div className='head-text'>IN PROGRESS</div>
                    </div>
                    {renderBoardColumn('progress')}
                </div>
            </div>
            <div className='board-cards'>
                <div className='board-item'>
                    <div className='board-head'>
                        <div className='border-border' style={{ backgroundColor: getBoardColor('review') }}></div>
                        <div className='head-text'>REVIEW</div>
                    </div>
                    {renderBoardColumn('review')}
                </div>
            </div>
            <div className='board-cards'>
                <div className='board-item'>
                    <div className='board-head'>
                        <div className='border-border' style={{ backgroundColor: getBoardColor('completed') }}></div>
                        <div className='head-text'>COMPLETED</div>
                    </div>
                    {renderBoardColumn('completed')}
                </div>
            </div>
        </div>
    )
}

export default Board