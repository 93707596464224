import React from 'react';
import './style.scss';
import HaptoLogo from '../../assets/images/navbar/slikk-logo.png'

const AuthLayout = (props) => {

    return (
        <div className='auth-container'>
            <div className='left-container'>
                <img src={HaptoLogo} className="logo-img" alt="logo" />
            </div>
            {props.children}
        </div>
    )
}

export default AuthLayout