export const OngoingProjectList = [
    {
        name: 'Project Alpha',
        status: 'On Track',
        priority: 'High',
        desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At.',
        due: '22/09/2022',
        owner: 'Karthick Anbu',
        owner_position: 'Asst Manager',
        progress: 80
    },
    {
        name: 'Project Alpha',
        status: 'On Track',
        priority: 'High',
        desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At.',
        due: '22/09/2022',
        owner: 'Karthick Anbu',
        owner_position: 'Asst Manager',
        progress: 17
    },
    {
        name: 'Project Alpha',
        status: 'On Track',
        priority: 'High',
        desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At.',
        due: '22/09/2022',
        owner: 'Karthick Anbu',
        owner_position: 'Asst Manager',
        progress: 37
    }
]