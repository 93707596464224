import { FieldTimeOutlined, PlusCircleOutlined, RobotOutlined } from '@ant-design/icons';
import { Avatar, Button } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../../common/CustomModal';
import AddProject from './addproject';
import { OngoingProjectList } from './data';
import './style.scss';

const Projects = (props) => {
    const navigate = useNavigate();
    const [showProjectModal, setShowProjectModal] = useState(false);

    return (
        <div className='projects-container'>
            {showProjectModal && <CustomModal close={() => setShowProjectModal(false)}>
                <AddProject close={() => setShowProjectModal(false)}/>
            </CustomModal>}
            <div className='inner-container'>
                <div className='hapto-flex'>
                    <div className='header-name'>Ongoing Projects</div>
                    <Button className='filter-button' onClick={() => setShowProjectModal(true)}><PlusCircleOutlined /> New Project</Button>
                </div>

                <div className='project-list'>
                    {OngoingProjectList.map((item, index) => {
                        return (
                            <div key={index} className='project-item' onClick={() => navigate('/projects/100')}>
                                <div className='hapto-flex'>
                                    <div className='hapto-flex'>
                                        <RobotOutlined />
                                        <div className='project-details'>
                                            <div className='project-name'>{item.name}</div>
                                            <div className='priority'>{item.priority}</div>
                                        </div>
                                    </div>
                                    <div className='hapto-flex'>
                                        <div className='status'>{item.status}</div>
                                        <div className='status-dot' />
                                    </div>
                                </div>

                                <div className='project-desc'>{item.desc}</div>
                                <div className='project-due'>
                                    <FieldTimeOutlined />
                                    <div className='due-head'>Project Due</div>
                                    <div className='due-date'>{item.due}</div>
                                </div>

                                <div className='hapto-flex'>
                                    <div>
                                        <div className='owner'>Project Owner</div>
                                        <div className='profile-container'>
                                            <Avatar size="small" src="https://joeschmoe.io/api/v1/random" />
                                            <div>
                                                <div className='profile-name'>{item.owner}</div>
                                                <div className='profile-position'>{item.owner_position}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className='owner'>Total Progress</div>
                                        <div className='progress'>{item.progress}%</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Projects