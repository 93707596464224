import React, { useEffect, useState } from 'react';
import './style.scss';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';

let eventId = 0

const CalendarView = (props) => {
    const [eventsData, setEventsData] = useState([
        { title: 'event 1', status: '1', start: "2022-07-05 12:00", end: "2022-07-05 02:00" },
        { title: 'event 2', status: '2', start: "2022-07-05 08:00", end: "2022-07-05 09:00" },
        { title: 'event 3', status: '3', start: "2022-07-05 02:00", end: "2022-07-05 03:00" },
        { title: 'event 1', status: '1', start: "2022-07-06 12:00", end: "2022-07-06 02:00" },
        { title: 'event 2', status: '2', start: "2022-07-06 08:00", end: "2022-07-06 09:00" },
        { title: 'event 3', status: '3', start: "2022-07-06 02:00", end: "2022-07-06 03:00" },

        { title: 'all day event 1', status: '1', allDay: true, date: '2022-07-04' },
        { title: 'all day event 2', status: '2', allDay: true, date: '2022-07-04' },
        { title: 'all day event 3', status: '3', allDay: true, date: '2022-07-04' },
        { title: 'all day event 1', status: '1', allDay: true, date: '2022-07-07' },
        { title: 'all day event 2', status: '2', allDay: true, date: '2022-07-07' },
        { title: 'all day event 3', status: '3', allDay: true, date: '2022-07-07' },
    ])

    const getBackgroundColor = (status) => {
        const color = status === '1' ? '#F8F5FD' : status === '2' ? '#F1F9FC' : '#FDFBF2';
        return color
    }

    const getBorderColor = (status) => {
        const color = status === '1' ? '#A565FE' : status === '2' ? '#33BFFF' : '#FED764';
        return color
    }

    useEffect(() => {
        const updatedEventsData = eventsData?.map(item => {
            item.backgroundColor = getBackgroundColor(item.status)
            item.borderColor = getBorderColor(item.status)
            return item
        })
        setEventsData(updatedEventsData)
    }, [])

    const handleDateSelect = (selectInfo) => {
        let title = prompt('Please enter a new title for your event')
        let status = prompt('Please enter the status (1/2/3)')
        let calendarApi = selectInfo.view.calendar

        calendarApi.unselect() // clear date selection

        if (title) {
            calendarApi.addEvent({
                id: eventId++,
                title,
                status,
                start: "2022-07-06 08:00",
                end: "2022-07-06 09:00",
                allDay: false,
                backgroundColor: getBackgroundColor(status),
                borderColor: getBorderColor(status)
            })
        }
    }

    const renderEventContent = (eventInfo) => {
        return (
            <div className='event-container' style={{ backgroundColor: eventInfo.event.backgroundColor, borderColor: eventInfo.event.borderColor }}>
                <div>{eventInfo.event.title}</div>
            </div>
        )
    }

    const handleEventClick = (clickInfo) => {
        // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
        //     clickInfo.event.remove()
        // }
    }

    const handleEvents = (events) => {
        // this.setState({
        //     currentEvents: events
        // })
    }

    return (
        <div className='hapto-calendar-container'>
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                    left: 'today',
                    center: 'prev title next',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                }}
                initialView='dayGridMonth'
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                weekends={true}
                events={eventsData} // alternatively, use the `events` setting to fetch from a feed
                select={handleDateSelect}
                eventContent={renderEventContent} // custom render function
                eventClick={handleEventClick}
                eventsSet={handleEvents} // called after events are initialized/added/changed/removed
            />
        </div>
    )
}

export default CalendarView