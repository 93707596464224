import React, { useState } from 'react';
import { connect } from 'react-redux';
import './style.scss';
import ProjectIcon from '../../assets/images/home/project.svg'
import { Avatar, Button, Popover, Progress } from 'antd';
import { FeedData, GoalsItem, TaskItem } from './data';
import { LikeFilled, MacCommandFilled, MessageFilled, MoreOutlined, PlusCircleFilled } from '@ant-design/icons';
import NewEvent from '../pods/feed/newevent';
import CustomModal from '../../common/CustomModal';
import NewGoals from '../goals/newgoals';
import CreateTask from '../mytasks/createtask';
import NewPost from '../pods/feed/newpost';
import NewMeeting from '../pods/feed/newmeeting';
import Chat from '../chat';
import { useNavigate } from 'react-router-dom';
import NewPoll from '../pods/feed/newpoll';
import NewVideo from '../pods/feed/newvideo';
import { saveMeetingRoomQuickAction, saveShowCreateModal } from '../../redux/quickactions/actions';
import { Carousel } from 'react-responsive-carousel';

const Home = (props) => {
    const navigate = useNavigate();
    const { setShowCreate } = props;
    const [showNewEventModal, setShowNewEventModal] = useState(false);
    const [showNewGoalModal, setShowNewGoalModal] = useState(false);
    const [showNewTaskModal, setShowNewTaskModal] = useState(false);
    const [showNewPostModal, setShowNewPostModal] = useState(false);
    const [showNewMeetingModal, setShowNewMeetingModal] = useState(false);

    const [showNewPollModal, setShowNewPollModal] = useState(false);
    const [showNewVideoModal, setShowNewVideoModal] = useState(false);

    const moreContent = (
        <div className='popover-more-content'>
            <div className='hapto-text-dark content-item'>Hide Post</div>
            <div className='hapto-text-dark content-item delete'>Report Post</div>
        </div>
    );

    return (
        <div className='home-container'>
            {showNewEventModal && <CustomModal close={() => setShowNewEventModal(false)}>
                <NewEvent close={() => setShowNewEventModal(false)} />
            </CustomModal>}
            {showNewGoalModal && <CustomModal close={() => setShowNewGoalModal(false)}>
                <NewGoals close={() => setShowNewGoalModal(false)} />
            </CustomModal>}
            {showNewTaskModal && <CustomModal close={() => setShowNewTaskModal(false)}>
                <CreateTask close={() => setShowNewTaskModal(false)} />
            </CustomModal>}
            {!showNewPollModal && !showNewVideoModal && showNewPostModal && <CustomModal close={() => setShowNewPostModal(false)}>
                <NewPost showPollModal={setShowNewPollModal} showVideoModal={setShowNewVideoModal} close={() => setShowNewPostModal(false)} />
            </CustomModal>}
            {showNewPollModal && <CustomModal close={() => setShowNewPostModal(false)}>
                <NewPoll close={() => setShowNewPollModal(false)} />
            </CustomModal>}
            {showNewVideoModal && <CustomModal close={() => setShowNewPostModal(false)}>
                <NewVideo close={() => setShowNewVideoModal(false)} />
            </CustomModal>}
            {showNewMeetingModal && <CustomModal close={() => setShowNewMeetingModal(false)}>
                <NewMeeting close={() => setShowNewMeetingModal(false)} />
            </CustomModal>}
            <Chat />
            <div className='header-wrapper'>
                <div className='header-item'>
                    <Carousel showIndicators={false} showStatus={false} showThumbs={false} infiniteLoop={true}>
                        <div className='carousel-header-item'>
                            <div className='hapto-flex'>
                                <div className='left-item'>
                                    Project Alpha
                                    <div className='project-status'>In Progress</div>
                                </div>
                                <img className='project-image' alt='project' src={ProjectIcon} />
                            </div>
                            <div className='progress-head'>Project Progress</div>
                            <div className='hapto-flex'>
                                <div className='due-date'>Due 22/04/2022</div>
                                <div className='due-date'>17 %</div>
                            </div>
                            <Progress percent={17} showInfo={false} />
                        </div>
                        <div className='carousel-header-item'>
                            <div className='hapto-flex'>
                                <div className='left-item'>
                                    Project Alpha
                                    <div className='project-status'>In Progress</div>
                                </div>
                                <img className='project-image' alt='project' src={ProjectIcon} />
                            </div>
                            <div className='progress-head'>Project Progress</div>
                            <div className='hapto-flex'>
                                <div className='due-date'>Due Date 22/04/2022</div>
                                <div className='due-date'>17 %</div>
                            </div>
                            <Progress percent={17} showInfo={false} />
                        </div>
                    </Carousel>
                </div>

                <div className='header-item'>
                    <Carousel showIndicators={false} showStatus={false} showThumbs={false} infiniteLoop={true}>
                        <div className='carousel-header-item'>
                            <div className='hapto-flex'>
                                <div className='left-item' style={{ flexDirection: 'column', alignItems: 'baseline' }}>
                                    Scheduled Meetings Today
                                    <div className='view-all' style={{ marginTop: 0 }} onClick={() => setShowCreate(true)}>View All</div>
                                </div>
                                <Button className='button-style' onClick={() => setShowNewMeetingModal(true)}>Schedule Meeting</Button>
                            </div>
                            <div className='hapto-flex'>
                                <div className='due-date'>
                                    Morning Briefing
                                    <div className='time'>at 10.00am IST</div>
                                </div>
                                <Button onClick={() => props.showMeetingRoom(true)} className='button-style'>Request to Join</Button>
                            </div>
                        </div>
                        <div className='carousel-header-item'>
                            <div className='hapto-flex'>
                                <div className='left-item' style={{ flexDirection: 'column', alignItems: 'baseline' }}>
                                    Scheduled Meetings Today
                                    <div className='view-all' style={{ marginTop: 0 }} onClick={() => setShowCreate(true)}>View All</div>
                                </div>
                                <Button className='button-style' onClick={() => setShowNewMeetingModal(true)}>Schedule Meeting</Button>
                            </div>
                            <div className='hapto-flex'>
                                <div className='due-date'>
                                    Morning Briefing
                                    <div className='time'>at 10.00am IST</div>
                                </div>
                                <Button onClick={() => props.showMeetingRoom(true)} className='button-style'>Request to Join</Button>
                            </div>
                        </div>
                    </Carousel>
                </div>

                <div className='header-item' style={{ border: 'none' }}>
                    <Carousel showIndicators={false} showStatus={false} showThumbs={false} infiniteLoop={true}>
                        <div className='carousel-header-item'>
                            <div className='hapto-flex'>
                                <div className='left-item' style={{ flexDirection: 'column', alignItems: 'baseline' }} >
                                    Events
                                    <div className='view-all' style={{ marginTop: 0 }} onClick={() => setShowCreate(true)}>View All</div>
                                </div>
                                <Button className='button-style' onClick={() => setShowNewEventModal(true)}>Create Event</Button>
                            </div>
                            <div className='hapto-flex no-event'>No events scheduled today</div>
                        </div>
                        <div className='carousel-header-item'>
                            <div className='hapto-flex'>
                                <div className='left-item' style={{ flexDirection: 'column', alignItems: 'baseline' }} >
                                    Events
                                    <div className='view-all' style={{ marginTop: 0 }} onClick={() => setShowCreate(true)}>View All</div>
                                </div>
                                <Button className='button-style' onClick={() => setShowNewEventModal(true)}>Create Event</Button>
                            </div>
                            <div className='hapto-flex no-event'>No events scheduled today</div>
                        </div>
                    </Carousel>
                </div>
            </div>

            <div className='feed-tasks-wrapper'>
                <div className='feed-tasks-item'>
                    <div className='hapto-flex'>
                        <div className='section-head'>Feed</div>
                        <Button className='button-style' onClick={() => setShowNewPostModal(true)}>New Post</Button>
                    </div>

                    {FeedData?.map((item, feedIndex) => {
                        return (
                            <div key={feedIndex} className="feed-item">
                                <div className="profile-wrapper">
                                    <Avatar size="medium" src="https://joeschmoe.io/api/v1/random" />
                                    <div className="feed-profile">
                                        <div className="profile-name">{item.name} <span className="time">{item?.time}</span></div>
                                        <div className="profile-name">{item.desc} <span className='hapto-text-dark' style={{ color: '#715BC8' }}> Task 1</span></div>
                                    </div>
                                    <div className="more-icon">
                                        <Popover content={moreContent} placement="rightTop" trigger="click">
                                            <MoreOutlined className="more" />
                                        </Popover>
                                    </div>

                                </div>

                                <div className="likes-wrapper">
                                    <div className="likes">{item.likes} Likes</div>
                                    <div className="hapto-flex">
                                        <div className="hapto-flex">
                                            <div className="each-section">
                                                <LikeFilled className="like-icon" />
                                                Like
                                            </div>
                                            <div className="each-section">
                                                <MessageFilled className="like-icon" />
                                                {item.comments?.length || ''} Comments
                                            </div>
                                        </div>

                                        <div className="each-section margin-zero">
                                            <MacCommandFilled className="like-icon" />
                                            {item?.commendations} Commendations
                                        </div>
                                    </div>
                                </div>

                                {item.comments?.map((comment, commentIndex) => {
                                    return (
                                        <div className="comments-container" key={commentIndex}>
                                            <Avatar size="small" src="https://joeschmoe.io/api/v1/random" />
                                            <div className="comment-name">{comment.person}</div>
                                            <div className="comment-message">{comment.message}</div>
                                            <div className="more-icon">
                                                <div className='hapto-text-light'>{comment.time}</div>
                                                {/* <MoreOutlined className="more" /> */}
                                            </div>
                                        </div>
                                    )
                                })}

                                <input className="comment-input" placeholder="Write your comment here..." />
                                {/* <div className='view-all' onClick={() => navigate('/pods')}>View More</div> */}
                            </div>
                        )
                    })}
                </div>

                <div className='feed-tasks-item'>
                    <div className='hapto-flex'>
                        <div className='section-head'>Tasks</div>
                        <Button className='button-style' onClick={() => setShowNewTaskModal(true)}><PlusCircleFilled /> New Task</Button>
                    </div>

                    {TaskItem?.map((task, taskIndex) => {
                        return (
                            <div className='task-item' style={{ border: task?.complete ? '1px solid #715BC8' : 'none' }} key={taskIndex}>
                                <div>
                                    <div className='task-name'>{task?.task}</div>
                                    <div className='task-desc'>{task?.desc}</div>
                                </div>
                                {task?.complete ? <div className='completed'>Completed</div> :
                                    <Avatar.Group size="small">
                                        <Avatar src="https://joeschmoe.io/api/v1/random" />
                                        <Avatar src="https://joeschmoe.io/api/v1/random" />
                                        <Avatar src="https://joeschmoe.io/api/v1/random" />
                                    </Avatar.Group>}
                            </div>
                        )
                    })}

                    <div className='view-all' onClick={() => navigate('/mytasks')}>View All</div>

                    <div className='hapto-flex goal-section'>
                        <div className='section-head'>Goals</div>
                        <Button className='button-style' onClick={() => setShowNewGoalModal(true)}><PlusCircleFilled /> New Goal</Button>
                    </div>

                    {GoalsItem?.map((goal, goalIndex) => {
                        return (
                            <div className='goal-item' key={goalIndex}>
                                <div className='hapto-flex'>
                                    <div className='goal-name'>{goal.name}</div>
                                    <div className='hapto-flex'>
                                        <div className='goal-status'>{goal?.status}</div>
                                        <MoreOutlined className="more" />
                                    </div>
                                </div>
                                <div className='goal-desc'>{goal.desc}</div>
                                <div className='hapto-flex'>
                                    <div className='hapto-text-light'>Estimated completion</div>
                                    <div className='hapto-text-light'>{goal.end_date}</div>
                                </div>
                                <div className='hapto-flex goal-progress'>
                                    <div className='hapto-text-light'>Goal Progress</div>
                                    <div className='hapto-text-dark'>{goal.progress}%</div>
                                </div>
                                <Progress strokeColor="#5433FF" percent={goal.progress} showInfo={false} />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}


const mapDispatchToProps = {
    showMeetingRoom: saveMeetingRoomQuickAction,
    setShowCreate: saveShowCreateModal
};

const mapStateToProps = state => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);