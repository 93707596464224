export const PodsListData = [
    {
        desc: 'Complete user research for new website',
        due: '26/03/2022',
        type: 'general',
        department: 'Design',
        priority: 'High',
        status: 'In Progress'
    },
    {
        desc: 'Work on identification of competitors',
        due: '26/03/2022',
        type: 'general',
        department: 'Design',
        priority: 'High',
        status: 'In Progress'
    }
]

export const PodsCompletedListData = [
    {
        desc: 'Complete user research for new website',
        due: '26/03/2022',
        type: 'general',
        department: 'Design',
        priority: 'High',
        status: 'Completed'
    },
    {
        desc: 'Complete user research for new website',
        due: '26/03/2022',
        type: 'general',
        department: 'Design',
        priority: 'High',
        status: 'Completed'
    },
    {
        desc: 'Complete user research for new website',
        due: '26/03/2022',
        type: 'general',
        department: 'Design',
        priority: 'High',
        status: 'Completed'
    },
    {
        desc: 'Complete user research for new website',
        due: '26/03/2022',
        type: 'general',
        department: 'Design',
        priority: 'High',
        status: 'Completed'
    }
]