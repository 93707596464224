import { FileExcelOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import './style.scss';

const BulkTeamMembers = (props) => {
    const { close } = props;

    return (
        <div className="bulk-team-member-container">
            <div className="hapto-flex bulk-team-member-head">
                <div className="hapto-flex">
                    <UsergroupAddOutlined style={{ marginRight: 5 }} />
                    Bulk Team Members
                </div>
                <div className="hapto-text-dark cancel" onClick={() => close()}>Cancel</div>
            </div>

            <div className="inner-member-container">
                <div className="bulk-team-desc">
                    <div className="hapto-text-dark">Import names, email addresses and custom properties</div>
                    <div className="hapto-text-dark">(department, location, role) from a CSV file.</div>
                </div>

                <Button className='select-button' onClick={() => close()}>Select File</Button>

                <div className="hapto-text-dark">Files</div>
                <div className="file-item hapto-flex">
                    <div className="hapto-flex">
                        <FileExcelOutlined className="file-image"/>
                        <div>
                            <div className="hapto-text-dark">Teamdetails.csv</div>
                            <div className="hapto-text-light">24/12/2021</div>
                        </div>
                    </div>
                </div>
                <div className="file-item hapto-flex">
                    <div className="hapto-flex">
                    <FileExcelOutlined className="file-image"/>
                        <div>
                            <div className="hapto-text-dark">Teamdetails.csv</div>
                            <div className="hapto-text-light">24/12/2021</div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='hapto-action-buttons team-mem'>
                <Button className='submit-button' onClick={() => close()}>Upload</Button>
            </div>

        </div>
    )
}

export default BulkTeamMembers