import React, { useEffect, useState } from 'react';
import './style.scss';

const EitherSideChart = (props) => {
    const { chartData } = props;
    const chartColor = ['#4BEB5B', '#4C9BEB', '#F39223'];
    const [highest, setHighest] = useState(0);
    const [lowest, setLowest] = useState(0);
    const [linesArray, setLinesArray] = useState([]);
    const [startingMargin, setStartingMargin] = useState(0);

    useEffect(() => {
        configureLinesArray()
    }, [])

    const configureLinesArray = () => {
        const wholeArray = [1, 2, 3, 4, 5, 6]
        let isNegative = false;
        const lowestPoint = chartData?.find(elem => elem.id === 3)?.value;
        const highestPoint = chartData?.find(elem => elem.id === 1)?.value;
        setHighest(highestPoint); setLowest(lowestPoint);
        if (lowestPoint < 0) isNegative = true;

        const difference = highestPoint - lowestPoint;
        const divisor = difference / 5;

        let negativeNumbersCount = 0;
        let positiveNumbersCount = 5;

        if (isNegative) {
            const numberCount = (lowestPoint * -1) / divisor;
            negativeNumbersCount = Math.ceil(numberCount)
        }
        positiveNumbersCount = 5 - negativeNumbersCount;

        let arrayLines = []
        wholeArray?.forEach(num => {
            if (num <= negativeNumbersCount) arrayLines.push(num * -1)
        })

        wholeArray?.forEach(num => {
            if (num <= positiveNumbersCount) arrayLines.push(num)
        })

        arrayLines = arrayLines.map(num => { return num * divisor })
        arrayLines.sort(function (a, b) { return a - b });
        arrayLines.splice(negativeNumbersCount, 0, 0)
        setLinesArray(arrayLines);
        setStartingMargin((negativeNumbersCount * 50) + 25);
    }

    return (
        <div className='eitherside-chart-container'>
            <div className='status-list hapto-flex'>
                {['Ahead', 'Behind', 'On Time'].map((item, index) => {
                    return (
                        <div key={index} className='status-item'>
                            <div className='status-color' style={{ backgroundColor: chartColor[index] }} />
                            <div className='hapto-text-light'>{item}</div>
                        </div>
                    )
                })}
            </div>

            <div className='hapto-flex'>
                <div className='label-container'>
                    {chartData.map((data, index) => {
                        return (
                            <div className='hapto-text-dark label-item' key={index}>
                                {data?.label}
                            </div>
                        )
                    })}
                </div>
                <div className='either-container'>
                    {linesArray?.map((item, index) => {
                        return (
                            <div className='line-item' key={index}>
                                <div className='line' />
                                <div className='line-label'>{item}</div>
                            </div>
                        )
                    })}
                    <div className='graph-container'>
                        {chartData?.map((item, index) => {
                            const current = item.value;
                            return (
                                <div className='bar-item' key={index}>
                                    <div className='bar-inner' style={{
                                        backgroundColor: chartColor[index],
                                        width: current < 0 ? (current * -1) : current,
                                        height: 25,
                                        marginLeft: current < 0 ? startingMargin + current : startingMargin,
                                        ...(current < 0 && {borderTopLeftRadius: 50, borderBottomLeftRadius: 50}),
                                        ...(current > 0 && {borderTopRightRadius: 50, borderBottomRightRadius: 50}),
                                    }} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EitherSideChart