import { LikeFilled, MacCommandFilled, MessageFilled, MoreOutlined } from "@ant-design/icons";
import { Avatar, Button, Popover } from "antd";
import React, { useState } from "react";
import CustomModal from "../../../common/CustomModal";
import { PodsFeedData } from "./data";
import NewPost from "./newpost";
import NewEvent from './newevent';
import './style.scss';
import NewPoll from "./newpoll";
import NewMeeting from './newmeeting';
import NewVideo from "./newvideo";

const PodsFeeds = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [showNewEventModal, setShowNewEventModal] = useState(false);
    const [showNewPollModal, setShowNewPollModal] = useState(false);
    const [showNewVideoModal, setShowNewVideoModal] = useState(false);
    const [showNewMeetingModal, setShowNewMeetingModal] = useState(false);

    const moreContent = (
        <div className='popover-more-content'>
            <div className='hapto-text-dark content-item'>Hide Post</div>
            <div className='hapto-text-dark content-item delete'>Delete Post</div>
        </div>
    );

    return (
        <div className="pods-feeds-container">
            {!showNewPollModal && !showNewVideoModal && showModal && <CustomModal close={() => setShowModal(false)}>
                <NewPost showPollModal={setShowNewPollModal} showVideoModal={setShowNewVideoModal} close={() => setShowModal(false)} />
            </CustomModal>}
            {showNewEventModal && <CustomModal close={() => setShowNewEventModal(false)}>
                <NewEvent close={() => setShowNewEventModal(false)} />
            </CustomModal>}
            {showNewPollModal && <CustomModal close={() => setShowModal(false)}>
                <NewPoll close={() => setShowNewPollModal(false)} />
            </CustomModal>}
            {showNewVideoModal && <CustomModal close={() => setShowModal(false)}>
                <NewVideo close={() => setShowNewVideoModal(false)} />
            </CustomModal>}
            {showNewMeetingModal && <CustomModal close={() => setShowNewMeetingModal(false)}>
                <NewMeeting close={() => setShowNewMeetingModal(false)} />
            </CustomModal>}
            <div className="header-wrapper flex-view">
                <Button className="button-style" onClick={() => setShowNewMeetingModal(true)}>Schedule Meeting</Button>
                <div className="right-header">
                    <Button className="button-style" onClick={() => setShowNewEventModal(true)}>Create Event</Button>
                    <Button className="button-style button-margin" onClick={() => setShowModal(true)}>New Post</Button>
                </div>
            </div>

            {PodsFeedData?.map((item, index) => {
                return (
                    <div key={index} className="feed-item">
                        <div className="profile-wrapper">
                            <Avatar size="medium" src="https://joeschmoe.io/api/v1/random" />
                            <div className="feed-profile">
                                <div className="profile-name">{item.name} <span className="time">{item?.time}</span></div>
                                <div className="profile-name">{item.desc}</div>
                            </div>
                            <Popover content={moreContent} placement="rightTop" trigger="click">
                                <div className="more-icon">
                                    <MoreOutlined className="more" />
                                </div>
                            </Popover>

                        </div>

                        <div className="likes-wrapper">
                            <div className="likes">{item.likes} Likes</div>
                            <div className="flex-view">
                                <div className="flex-view">
                                    <div className="each-section">
                                        <LikeFilled className="like-icon" />
                                        Like
                                    </div>
                                    <div className="each-section">
                                        <MessageFilled className="like-icon" />
                                        {item.comments?.length || ''} Comments
                                    </div>
                                </div>

                                <div className="each-section margin-zero">
                                    <MacCommandFilled className="like-icon" />
                                    {item?.commendations} Commendations
                                </div>
                            </div>
                        </div>

                        {item.comments?.map((comment, commentIndex) => {
                            return (
                                <div className="comments-container" key={commentIndex}>
                                    <Avatar size="small" src="https://joeschmoe.io/api/v1/random" />
                                    <div className="comment-name">{comment.person}</div>
                                    <div className="comment-message">{comment.message}</div>
                                    <div className="more-icon">
                                        <MoreOutlined className="more" />
                                    </div>
                                </div>
                            )
                        })}

                        <input className="comment-input" placeholder="Write your comment here..." />
                    </div>
                )
            })}
        </div>
    )
}

export default PodsFeeds