export const GoalsList = [
    {
        name:'Goal 1',
        priority: 'High Priority',
        status: 'Active',
        desc: 'Achieve 20% increase in revenue in 4th quarter',
        owner: 'Martha Wayne',
        Assigne: 5,
        progress: 31,
        start_date: '2/1/2022',
        end_date: '28/10/2022'
    },
    {
        name:'Goal 2',
        priority: 'High Priority',
        status: 'On Hold',
        desc: 'Achieve 20% increase in revenue in 4th quarter',
        owner: 'Martha Wayne',
        Assigne: 5,
        progress: 76,
        start_date: '2/1/2022',
        end_date: '28/10/2022'
    },
    {
        name:'Goal 3',
        priority: 'High Priority',
        status: 'Active',
        desc: 'Achieve 20% increase in revenue in 4th quarter',
        owner: 'Martha Wayne',
        Assigne: 5,
        progress: 50,
        start_date: '2/1/2022',
        end_date: '28/10/2022'
    },
    {
        name:'Goal 3',
        priority: 'High Priority',
        status: 'Active',
        desc: 'Achieve 20% increase in revenue in 4th quarter',
        owner: 'Martha Wayne',
        Assigne: 5,
        progress: 50,
        start_date: '2/1/2022',
        end_date: '28/10/2022'
    },
    {
        name:'Goal 3',
        priority: 'High Priority',
        status: 'Active',
        desc: 'Achieve 20% increase in revenue in 4th quarter',
        owner: 'Martha Wayne',
        Assigne: 5,
        progress: 50,
        start_date: '2/1/2022',
        end_date: '28/10/2022'
    },
]