export const PriorityList = [
    {label:'High Priority', value:'high'},
    {label:'Medium Priority', value:'medium'},
    {label:'Low Priority', value:'low'}
]

export const StatusList = [
    {label:'To Do', value:'todo'},
    {label:'In Progress', value:'inprogress'},
    {label:'Review', value:'review'},
    {label:'Done', value:'done'}
]