import { CaretDownOutlined, FilterFilled, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../../common/CustomModal';
import GanttScreen from '../mytasks/gantt';
import AllPods from './allpods';
import PodsBoards from './board';
import PodsFeeds from './feed';
import PodsFiles from './files';
import PodsList from './list';
import './style.scss';
import PodsListFilter from './list/filter';
import AllPodsImages from '../../assets/images/tabs/allpods.png';
import FeedImage from '../../assets/images/tabs/feed.png';
import ListImage from '../../assets/images/tabs/list.png';
import BoardsImage from '../../assets/images/tabs/boards.png';
import GanntImage from '../../assets/images/tabs/gantt.png';
import FilesImage from '../../assets/images/tabs/files.png';

import AllPodsActiveImages from '../../assets/images/tabs/allpods-active.png';
import FeedActiveImage from '../../assets/images/tabs/feed-active.png';
import ListActiveImage from '../../assets/images/tabs/list-active.png';
import BoardsActiveImage from '../../assets/images/tabs/boards-active.png';
import GanntActiveImage from '../../assets/images/tabs/gantt-active.png';
import FilesActiveImage from '../../assets/images/tabs/files-active.png';

const { TabPane } = Tabs;

const Pods = (props) => {
    const navigate = useNavigate();
    const [activeKey, setActiveKey] = useState('1');
    const [showModal, setShowModal] = useState(false);

    const operations = <div className='hapto-flex'>
        {activeKey === '1' && <div className="pods-search-container">
            <div className="search-icon"><SearchOutlined /></div>
            <input placeholder="Search" className='input-area' />
        </div>}
       {activeKey === '1' && <Button className='create-button' onClick={() => navigate('/pods/new')}><PlusCircleOutlined />New Pod</Button>}
    </div>

    return (
        <div className='pods-container'>
            {activeKey !== '1' && <div className='pod-name'>POD 1</div>}
            {showModal && <CustomModal close={() => setShowModal(false)}>
                <PodsListFilter close={() => setShowModal(false)} />
            </CustomModal>}
            <Tabs defaultActiveKey={activeKey} activeKey={activeKey} onChange={setActiveKey} tabBarStyle={{ color: "#1D2121" }} tabBarExtraContent={operations}>
                <TabPane tab={
                    activeKey === '1' && <span className='tab-name'>
                        {activeKey === '1' && <div className='active-tab' />}
                        <img src={activeKey === '1' ? AllPodsActiveImages : AllPodsImages} alt="allpods" className='icon-style' />
                        All Pods
                    </span>} key="1">
                    <AllPods changeTab={setActiveKey} />
                </TabPane>
                <TabPane tab={
                    activeKey !== '1' && <span className='tab-name'>
                        {activeKey === '2' && <div className='active-tab' />}
                        <img src={activeKey === '2' ? FeedActiveImage : FeedImage} alt="allpods" className='icon-style' />
                        Feed
                    </span>} key="2">
                    <PodsFeeds />
                </TabPane>
                <TabPane tab={
                    activeKey !== '1' && <span className='tab-name'>
                        {activeKey === '3' && <div className='active-tab' />}
                        <img src={activeKey === '3' ? ListActiveImage : ListImage} alt="allpods" className='icon-style' />
                        Task
                    </span>} key="3">
                    <PodsList />
                </TabPane>
                <TabPane tab={
                    activeKey !== '1' && <span className='tab-name'>
                        {activeKey === '4' && <div className='active-tab' />}
                        <img src={activeKey === '4' ? BoardsActiveImage : BoardsImage} alt="allpods" className='icon-style' />
                        Boards
                    </span>} key="4">
                    <PodsBoards />
                </TabPane>
                <TabPane tab={
                    activeKey !== '1' && <span className='tab-name'>
                        {activeKey === '5' && <div className='active-tab' />}
                        <img src={activeKey === '5' ? GanntActiveImage : GanntImage} alt="allpods" className='icon-style' />
                        Gantt
                    </span>} key="5">
                    <GanttScreen />
                </TabPane>
                <TabPane tab={
                    activeKey !== '1' && <span className='tab-name'>
                        {activeKey === '6' && <div className='active-tab' />}
                        <img src={activeKey === '6' ? FilesActiveImage : FilesImage} alt="allpods" className='icon-style' />
                        Files
                    </span>} key="6">
                    <PodsFiles />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default Pods