import React, { useState } from 'react';
import './style.scss';
import { useNavigate } from "react-router-dom";
import CustomInput from '../../common/CustomInput';
import CustomButton from '../../common/CustomButton';
import AuthLayout from '../authlayout';
import BGImage from '../../assets/images/authentication/hapto-background.png'

const Login = (props) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);

    const handleLogin = () => {
        navigate('/home')
    }

    return (
        <AuthLayout>
            <div className='login-container'>
                <img src={BGImage} alt="bg-image" className="login-bg-image" />
                <div className='sign-up-wrapper'>
                    <div className='new-user'>New User?</div>
                    <div className='sign-up' onClick={() => navigate('/signup')}>
                        Sign Up
                    </div>
                </div>
                <div className='card-wrapper'>
                    <div className='bg-dots'>
                        {Array(144).fill().map((_, index) => {
                            return <div key={index} className='dot-item' />
                        })}
                    </div>
                    <div className='card-head'>Log In</div>
                    <CustomInput required label="Email Address" value={email} onChange={setEmail} />
                    <CustomInput required protect label="Password" value={password} onChange={setPassword} />
                    <CustomButton label="Log In" onClick={handleLogin} />
                    <div className='forgot-password'>Forgot Password?</div>
                </div>
            </div>
        </AuthLayout>
    )
}

export default Login