import { EditOutlined } from "@ant-design/icons";
import { Avatar, Input, Switch } from "antd";
import React, { useState } from "react";
import { AllPodsData } from "./data";
import './style.scss';

const AllPods = (props) => {
    const [podsData, setPodsData] = useState(AllPodsData);
    const [isEdit, setIsEdit] = useState(null)

    const handleStatus = (checked, index, event) => {
        event.stopPropagation()
        const updatedPods = podsData?.map((item) => {
            if (index === item.id) item.active = checked
            return item
        })
        setPodsData(updatedPods)
    }

    return (
        <div className="all-pods-container">
            {isEdit && <div className="pods-item edit-modal">
                <div className="pod-header">
                    <div className="pod-text head-text">{isEdit?.name}</div>
                    <div className="head-flex" onClick={() => setIsEdit(null)}>
                        Cancel
                    </div>
                </div>
                <div className="inner-container">
                    <div className="pod-status">
                        <div className="pod-text">Status</div>
                        <div className="pods-flex">
                            <div className="status">Active</div>
                            <Switch size="small" className="switch-style" checked={isEdit?.active} onChange={(checked, event) => handleStatus(checked, isEdit.id, event)} />
                        </div>
                    </div>

                    <div className="hapto-text-dark">Pod Name</div>
                    <Input className='input-area' autoSize />


                    <div className="hapto-text-dark">Pod Admin</div>
                    <div className="add-container">
                        <div className="add-input">
                            <Avatar size="small" src="https://joeschmoe.io/api/v1/random" />
                            <div className="hapto-text-dark" style={{ marginLeft: 10 }}>Karthick Anbu</div>
                        </div>
                        <div className="add-action">+ Add</div>
                    </div>

                    <div className="hapto-text-dark">Add Members</div>
                    <div className="add-container">
                        <div className="add-input">

                        </div>
                        <div className="add-action">+ Add</div>
                    </div>

                    <div className="members-container">
                        {[1, 2, 3, 4].map(item => {
                            return (
                                <div className="members-item hapto-flex" key={item}>
                                    <div className="hapto-flex">
                                        <Avatar size="small" src="https://joeschmoe.io/api/v1/random" />
                                        <div className="hapto-text-dark" style={{ marginLeft: 10 }}>Karthick Anbu</div>
                                    </div>
                                    <div className="remove">Remove</div>
                                </div>
                            )
                        })}
                    </div>

                    <div className="hapto-flex">
                        <div className="remove">Delete Pod</div>
                        <div className="add-action">Save</div>
                    </div>
                </div>
            </div>}
            {podsData?.map((item, index) => {
                return (
                    <div key={index} className="pods-item" onClick={() => props.changeTab('2')} style={{ opacity: isEdit ? 0.5 : 1 }}>
                        <div className="pod-header">
                            <div className="pod-text head-text">{item?.name}</div>
                            <div className="head-flex" onClick={(e) => {e.stopPropagation();setIsEdit(item)}}>
                                <EditOutlined className="edit-icon" />
                                Edit
                            </div>
                        </div>

                        <div className="inner-container">
                            <div className="pod-status">
                                <div className="pod-text">Status</div>
                                <div className="pods-flex">
                                    <div className="status">Active</div>
                                    <Switch size="small" className="switch-style" checked={item?.active} onChange={(checked, event) => handleStatus(checked, item.id, event)} />
                                </div>
                            </div>

                            <div className="admin-details">
                                <div className="pods-flex" style={{ marginBottom: 15 }}>
                                    <div className="pod-text">Pod Admin</div>
                                    <div className="profile-flex">
                                        <Avatar size="medium" src="https://joeschmoe.io/api/v1/random" />
                                        <div className="admin-desc">
                                            <div className="admin-name">{item?.admin}</div>
                                            <div className="admin-role">{item?.admin_role}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="pods-flex">
                                    <div className="pod-text">Members</div>
                                    <div className="profile-flex">
                                        <Avatar.Group size="small">
                                            <Avatar src="https://joeschmoe.io/api/v1/random" />
                                            <Avatar src="https://joeschmoe.io/api/v1/random" />
                                            <Avatar src="https://joeschmoe.io/api/v1/random" />
                                        </Avatar.Group>
                                        <div className="admin-role"> + {item?.members} others</div>
                                    </div>
                                </div>
                            </div>

                            <div className="pod-details pods-flex">
                                <div className="pod-text">Date Created</div>
                                <div className="pod-value">{item.created_date}</div>
                            </div>
                            <div className="pod-details pods-flex">
                                <div className="pod-text">Departments</div>
                                <div className="pods-flex">
                                    <div className="pod-value">{item.departments[0]}</div>
                                    <div className="pod-additional">+ {item.departments?.length} Others</div>
                                </div>
                            </div>
                            <div className="pod-details pods-flex">
                                <div className="pod-text">Assigned Projects</div>
                                <div className="pods-flex">
                                    <div className="pod-value">{item.projects[0]}</div>
                                    <div className="pod-additional">+ {item.projects?.length} Others</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default AllPods