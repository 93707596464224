import { ContactsOutlined, ControlOutlined, ProfileOutlined, UserAddOutlined, UsergroupAddOutlined, UserOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import CompanyProfile from './companyprofile';
import HelpCenter from './helpcenter';
import MyProfile from './myprofile';
import './style.scss';
import TeamProfile from './teamprofile';
import Employees from './employees';
import Departments from './departments';

const Profile = (props) => {
    const [selectedTab, setSelectedTab] = useState('me');

    return (
        <div className='profile-screen-container'>
            <div className='inner-container'>
                <div className="tab-container hapto-flex">
                    <div className="hapto-flex">
                        <div
                            onClick={() => setSelectedTab('me')}
                            className="tab-style hapto-text-dark">
                            <UserOutlined className='tab-icon' />
                            My Profile
                            {selectedTab === "me" && <div className="active-tab" />}
                        </div>
                        <div
                            onClick={() => setSelectedTab('team')}
                            className="tab-style hapto-text-dark">
                            <UsergroupAddOutlined className='tab-icon' />
                            Team Profile
                            {selectedTab === "team" && <div className="active-tab" />}
                        </div>
                        <div
                            onClick={() => setSelectedTab('company')}
                            className="tab-style hapto-text-dark">
                            <ProfileOutlined className='tab-icon' />
                            Company Profile
                            {selectedTab === "company" && <div className="active-tab" />}
                        </div>
                        <div
                            onClick={() => setSelectedTab('help')}
                            className="tab-style hapto-text-dark">
                            <ControlOutlined className='tab-icon' />
                            Help Center
                            {selectedTab === "help" && <div className="active-tab" />}
                        </div>
                        <div
                            onClick={() => setSelectedTab('departments')}
                            className="tab-style hapto-text-dark">
                            <ContactsOutlined className='tab-icon'/>
                            Departments
                            {selectedTab === "departments" && <div className="active-tab" />}
                        </div>
                        <div
                            onClick={() => setSelectedTab('employees')}
                            className="tab-style hapto-text-dark">
                            <UserAddOutlined className='tab-icon' />
                            Employees
                            {selectedTab === "employees" && <div className="active-tab" />}
                        </div>
                    </div>
                </div>

                {selectedTab === 'me' && <MyProfile />}
                {selectedTab === 'team' && <TeamProfile />}
                {selectedTab === 'company' && <CompanyProfile />}
                {selectedTab === 'help' && <HelpCenter />}
                {selectedTab === 'employees' && <Employees />}
                {selectedTab === 'departments' && <Departments />}
            </div>
        </div>
    )
}

export default Profile