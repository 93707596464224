import { VideoCameraOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import './style.scss';

const NewVideo = (props) => {
    const { close } = props;

    return (
        <div className='new-video-container'>
            <div className='hapto-flex'>
                <div className='new-video'><VideoCameraOutlined style={{ marginRight: 5 }} />New Video</div>
                <div className='delete-video'>Delete Video</div>
            </div>

            <div className='hapto-text-dark'>Upload Video</div>
            <div className='upload-area hapto-text-dark'>
                <VideoCameraOutlined />
                <div className='hapto-text-dark'>Drag and drop files here</div>
                or
                <Button className='browse-button' onClick={() => close()}>Browse</Button>
            </div>

            <div className='hapto-text-dark'>Description</div>
            <TextArea className='text-area' autoSize placeholder='Type here!!' />

            <div className='hapto-action-buttons'>
                <Button className='submit-button cancel' onClick={() => close()}>Cancel</Button>
                <Button className='submit-button' onClick={() => close()}>Save</Button>
            </div>
        </div>
    )
}

export default NewVideo