import { AudioOutlined, CalendarOutlined, LinkOutlined, PlusOutlined, UsergroupDeleteOutlined } from '@ant-design/icons';
import { Avatar, Button, DatePicker, TimePicker } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import './style.scss';
import moment from 'moment';
import LocationIcon from '../../../../assets/images/modals/location.png'

const NewEvent = (props) => {
    const { close } = props

    return (
        <div className='new-event-container'>
            <div className='hapto-flex'>
                <div className='hapto-flex'>
                    <CalendarOutlined className='icon-color' />
                    <div className='hapto-text-dark head-label'>Create Event</div>
                </div>
            </div>

            <div className='hapto-flex date-time-wrapper'>
                <div className='hapto-flex date-time-item'>
                    <div className='hapto-text-dark'>Date</div>
                    <DatePicker className='select-style' />
                </div>
                <div className='hapto-flex date-time-wrapper'>
                    <div className='hapto-text-dark'>Time</div>
                    <TimePicker className='select-style' placeholder='Select Time' defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} />
                </div>
            </div>

            <div className='hapto-flex'>
                <img src={LocationIcon} alt="location" className="location-icon"/>
                <input placeholder="Add Location" className='input-area' />
            </div>

            <div className='hapto-flex user-details'>
                <div className='hapto-flex'>
                    <UsergroupDeleteOutlined className='user-avatar icon-color' />
                    <Avatar className='user-avatar' size="small" src="https://joeschmoe.io/api/v1/random" />
                    <Avatar className='user-avatar' size="small" src="https://joeschmoe.io/api/v1/random" />
                    <Avatar className='user-avatar' size="small" src="https://joeschmoe.io/api/v1/random" />
                    <div className='more-avatar'><PlusOutlined /></div>
                </div>
                <div className='hapto-flex'>
                    <div className='hapto-text-dark pod-label'>Pod Id</div>
                    <input placeholder="Pod Id" className='input-area' />
                </div>
            </div>

            <div className='text-area-label hapto-text-dark'>Event Description</div>
            <TextArea className='text-area' autoSize />

            <div className='text-area-label hapto-text-dark'>Comments</div>
            <div className='comments-container'>
                <TextArea className='text-area' autoSize placeholder='Type here...' />
                <div className='action-buttons'>
                    <div className='action-item'><LinkOutlined /></div>
                    <div className='action-item'><AudioOutlined /></div>
                </div>

                <div className='hapto-action-buttons comment-buttons'>
                    <Button className='submit-button' onClick={() => null}>Comment</Button>
                </div>
            </div>

            <div className='hapto-action-buttons'>
                <Button className='submit-button cancel' onClick={() => close()}>Cancel</Button>
                <Button className='submit-button' onClick={() => close()}>Submit</Button>
            </div>
        </div>
    )
}

export default NewEvent