import { BorderOuterOutlined } from '@ant-design/icons';
import { Button, DatePicker, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { PriorityList, StatusList } from '../../../constants/lov';
import './style.scss'

const CreateTask = (props) => {
    const { close } = props;

    return (
        <div className='create-task-container'>
            <div className='hapto-flex'>
                <div className='hapto-flex'>
                    <BorderOuterOutlined />
                    <div className='hapto-text-dark head-label'>New Task</div>
                </div>
            </div>

            <div className='text-area-label hapto-text-dark'>Task Description</div>
            <TextArea className='text-area' autoSize />

            <div className='other-inputs'>
                <div className='other-item'>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Task Type</div>
                        <Select className='select-style' options={[{label:'General', value:'general'},{label:'Meeting', value:'meeting'}, {label:'Report', value:'report'}]}/>
                    </div>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Assign Owner</div>
                        <Select className='select-style' />
                    </div>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Start Date</div>
                        <DatePicker className='select-style' />
                    </div>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Due Date</div>
                        <DatePicker className='select-style' />
                    </div>
                </div>

                <div className='other-item'>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Assign Pod</div>
                        <Select className='select-style' />
                    </div>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Assign Collaborator</div>
                        <Select className='select-style' />
                    </div>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Priority</div>
                        <Select className='select-style' options={PriorityList}/>
                    </div>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Status</div>
                        <Select className='select-style' options={StatusList}/>
                    </div>
                </div>
            </div>

            <div className='hapto-action-buttons'>
                <Button className='submit-button cancel' onClick={() => close()}>Cancel</Button>
                <Button className='submit-button' onClick={() => close()}>Save</Button>
            </div>
        </div>
    )
}

export default CreateTask