import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Routes, Route, useLocation } from "react-router-dom";
import Goals from './components/goals';
import Reports from './components/reports';
import Home from './components/home';
import Login from './components/login';
import MyTasks from './components/mytasks';
import Navbar from './components/navbar';
import Pods from './components/pods';
import NewPod from './components/pods/newpod';
import Profile from './components/profile';
import Projects from './components/projects';
import Signup from './components/signup';
import TopBar from './components/topbar';
import ProjectDetails from './components/projects/project-details';
import Notifications from './components/notifications';
import Onboarding from './components/onboarding';
import OTPScreen from './components/otp';
import QuickCreate from './components/quickcreate';
import MeetingRoom from './components/meetingroom';
import { saveShowCreateModal } from './redux/quickactions/actions';
import ReportsAndAnalytics from './components/reportsanalytics';

const App = (props) => {
  const location = useLocation();
  const { fullScreenMeetingRoom, showCreate } = props;
  const [showNav, setShowNav] = useState(false);

  useEffect(() => {
    toggleNavBar()
  }, [location?.pathname])


  const toggleNavBar = () => {
    const path = location?.pathname;
    if (path === "/" || path?.includes('login') || path?.includes('signup') || path?.includes('onboarding') || path?.includes('otp')) setShowNav(false)
    else setShowNav(true)
  }

  return (
    <div>
      {showNav && !fullScreenMeetingRoom && <Navbar />}
      {showNav && <TopBar />}
      <div className={showNav ? 'content-container' : ''}>
        {showCreate ? <QuickCreate handleCreate={() => props.setShowCreate(false)} /> :
          (props.showMeetingRoom ? <MeetingRoom /> :
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/otp" element={<OTPScreen />} />
              <Route path="/onboarding" element={<Onboarding />} />
              <Route path="/home" element={<Home />} />
              <Route path="/mytasks" element={<MyTasks />} />
              <Route path="/pods" element={<Pods />} />
              <Route path="/pods/new" element={<NewPod />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/reportsandanalytics" element={<ReportsAndAnalytics />} />
              <Route path="/goals" element={<Goals />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/projects/:id" element={<ProjectDetails />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/notifications" element={<Notifications />} />
            </Routes>)}
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  setShowCreate: saveShowCreateModal
};

const mapStateToProps = state => {
  return {
    activeNavItem: state.navbar.active,
    navItems: state.navbar.items,
    showMeetingRoom: state.quickAction.showMeetingRoom,
    fullScreenMeetingRoom: state.quickAction.fullScreenMeetingRoom,
    showCreate: state.quickAction.showCreate
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);