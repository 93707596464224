import { Button, Select } from "antd";
import React from "react";
import { StatusList } from "../../constants/lov";
import './style.scss';

const ConfirmationModal = (props) => {
    const { label, action, status, close, closeAll, addtionalMessage, success, change } = props

    return (
        <div className="confirmation-modal-container">
            <div className="confirm-header hapto-text-dark" style={success && { backgroundColor: '#715BC8', color: '#fff' }}>{label}</div>

            <div className="hapto-flex">
                <div className="confirm-details-item">
                    <div className="hapto-text-dark">Task Description</div>
                    <div className="hapto-text-light">{action}</div>
                </div>
                {status && <div className="confirm-details-item">
                    <div className="hapto-text-dark">{change && 'Change '}Status</div>
                    {change ? <Select options={StatusList} className="select-style"/> : <div className="hapto-text-light">{status}</div>}
                </div>}
            </div>

            <div className="hapto-text-dark">
                {addtionalMessage}
            </div>

            <div className='hapto-action-buttons'>
                <Button className='submit-button cancel' onClick={() => close()}>Cancel</Button>
                <Button className='submit-button' onClick={() => closeAll()}>Confirm</Button>
            </div>
        </div>
    )
}

export default ConfirmationModal