import { CaretDownOutlined, FilterFilled, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Avatar, Button, Table } from "antd";
import React, { useState } from "react";
import { TeamsData } from "./data";
import TeamProfileFilter from "./filter";
import './style.scss';
import CustomModal from '../../../common/CustomModal';
import NewTeamMember from "./newteammember";

const TeamProfile = (props) => {
    const [searchText, setSearchText] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showNewTeamMemberModal, setShowNewTeamMemberModal] = useState(false);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: {
                compare: (a, b) => a.name - b.name,
            },
            render: (text, record) => (
                <div className="profile-column-flex">
                    <Avatar size="medium" src="https://joeschmoe.io/api/v1/random" />
                    <div className="profile-desc">
                        <div className="hapto-text-dark">{text}</div>
                        <div className="hapto-text-light">{record?.role}</div>
                    </div>
                </div>
            )
        },
        {
            title: 'Emp Id',
            dataIndex: 'empid',
            sorter: {
                compare: (a, b) => a.empid - b.empid,
            },
            render: (text, record) => (
                <div className="hapto-text-dark">{record?.empid}</div>
            )
        },
        {
            title: 'Access',
            dataIndex: 'access',
            sorter: {
                compare: (a, b) => a.access - b.access,
            },
            render: (text, record) => (
                <div className="hapto-text-dark">{record?.access}</div>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            sorter: {
                compare: (a, b) => a.status - b.status,
            },
            render: (text, record) => (
                <div className="hapto-text-dark">{record?.status}</div>
            )
        },
        {
            title: 'Pod Status',
            dataIndex: 'podstatus',
            sorter: {
                compare: (a, b) => a.podstatus - b.podstatus,
            },
            render: (text, record) => (
                <div className="hapto-text-dark">{record?.podstatus}</div>
            )
        },
        {
            title: 'Department',
            dataIndex: 'department',
            sorter: {
                compare: (a, b) => a.department - b.department,
            },
            render: (text, record) => (
                <div className="hapto-text-dark">{record?.department}</div>
            )
        },
        {
            title: 'Joining Date',
            dataIndex: 'joiningdate',
            sorter: {
                compare: (a, b) => a.joiningdate - b.joiningdate,
            },
            render: (text, record) => (
                <div className="hapto-text-dark">{record?.joiningdate}</div>
            )
        },
    ];

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    return (
        <div className='team-profile-container'>
            {showModal && <CustomModal close={() => setShowModal(false)}>
                <TeamProfileFilter close={() => setShowModal(false)} />
            </CustomModal>}
            {showNewTeamMemberModal && <CustomModal close={() => setShowNewTeamMemberModal(false)}>
                <NewTeamMember close={() => setShowNewTeamMemberModal(false)} />
            </CustomModal>}
            <div className="actions-wrapper">
                <Button className='create-button' onClick={() => setShowNewTeamMemberModal(true)}><PlusCircleOutlined />Add Team Member</Button>
            </div>

            <div className="search-wrapper">
                <div className="search-filter hapto-flex">
                    <div className="search-box">
                        <SearchOutlined />
                        <input className="search-input" value={searchText} onChange={(event) => setSearchText(event.target.value)} placeholder="Search Team Member" />
                    </div>
                    <Button className='filter-button' style={{ backgroundColor: '#715BC8' }} onClick={() => setShowModal(true)}><FilterFilled /> Filter <CaretDownOutlined /></Button>
                </div>
                <div className="hapto-flex">
                    <div className="filtered-list">
                        <div className="hapto-text-light filtered-item">Assigned to Pod</div>
                        <div className="hapto-text-light filtered-item">Active</div>
                        <div className="hapto-text-light filtered-item">Active</div>
                    </div>
                    <div className="hapto-text-dark reset">Reset Filter</div>
                </div>
            </div>

            <Table columns={columns} dataSource={TeamsData} onChange={onChange} />
        </div>
    )
}

export default TeamProfile