import { BorderOuterOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, DatePicker, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import './style.scss';
import { PriorityList, StatusList } from '../../../constants/lov.js'

const AddProject = (props) => {
    const { close } = props

    return (
        <div className='add-project-container'>
            <div className='hapto-flex'>
                <div className='hapto-flex'>
                    <BorderOuterOutlined />
                    <div className='hapto-text-dark head-label'>New Project</div>
                </div>
                <div className='cancel-project' onClick={() => close()}><CloseOutlined /></div>
            </div>

            <div className='text-area-label hapto-text-dark'>Project Title</div>
            <TextArea className='text-area' autoSize />
            <div className='text-area-label hapto-text-dark'>Project Description</div>
            <TextArea className='text-area' autoSize />
            <div className='text-area-label hapto-text-dark'>Project Milestone</div>
            <div className='milestone-flex'>
                <div className='milestone-color' />
                <input className='milestone-input' placeholder='Enter your project milestone' />
                <div>
                    <DatePicker className='select-style' placeholder='Milestone Due Date'/>
                </div>
            </div>

            <Button className='add-button'>+ Add Milestone</Button>

            <div className='other-inputs'>
                <div className='other-item'>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Assign Owner</div>
                        <Select className='select-style' />
                    </div>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Start Date</div>
                        <DatePicker className='select-style' />
                    </div>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Due Date</div>
                        <DatePicker className='select-style' />
                    </div>
                </div>

                <div className='other-item'>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Assign Pod</div>
                        <Select className='select-style' />
                    </div>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Priority</div>
                        <Select className='select-style' options={PriorityList} />
                    </div>
                    <div className='hapto-flex other-item-style'>
                        <div className='hapto-text-dark other-label'>Status</div>
                        <Select className='select-style' options={StatusList} />
                    </div>
                </div>
            </div>

            <div className='hapto-action-buttons'>
                <Button className='submit-button cancel' onClick={() => close()}>Cancel</Button>
                <Button className='submit-button' onClick={() => close()}>Submit</Button>
            </div>
        </div>
    )
}

export default AddProject