import { PlusOutlined, UserOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import CustomInput from "../../../../common/CustomInput";
import './style.scss';

const NewTeamMember = (props) => {
    const { close } = props;
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [department, setDepartment] = useState(null);
    const [designation, setDesignation] = useState(null);
    const [access, setAccess] = useState(null);
    const [employeeId, setEmployeeId] = useState(null);
    const [gender, setGender] = useState(null);
    const [city, setCity] = useState(null);
    const [country, setCountry] = useState(null);

    return (
        <div className="new-team-member-container">
            <div className="hapto-flex new-team-member-head">
                <div className="hapto-flex">
                    <UserOutlined style={{ marginRight: 5 }} />
                    New Team Member
                </div>
                <div className="hapto-text-dark cancel" onClick={() => close()}>Cancel</div>
            </div>

            <div className="add-image-wrapper hapto-text-dark">
                <div className="add-image">
                    <PlusOutlined />
                </div>
                Add Image
            </div>

            <div className="inner-member-container">
                <div className="hapto-flex input-list">
                    <CustomInput half label="First Name" value={firstName} onChange={setFirstName} />
                    <CustomInput half label="Last Name" value={lastName} onChange={setLastName} />
                    <CustomInput half label="Email Address" value={email} onChange={setEmail} />
                    <CustomInput half label="Phone Number" value={phone} onChange={setPhone} />
                    <CustomInput half label="Department" value={department} onChange={setDepartment} />
                    <CustomInput half label="Designation" value={designation} onChange={setDesignation} />
                    <CustomInput half label="Access" value={access} onChange={setAccess} />
                    <CustomInput half label="Employee Id" value={employeeId} onChange={setEmployeeId} />
                    <CustomInput half label="Gender" value={gender} onChange={setGender} />
                    <CustomInput half label="City" value={city} onChange={setCity} />
                    <CustomInput half label="Country" value={country} onChange={setCountry} />
                </div>

                <div className='hapto-action-buttons team-mem'>
                    <div/>
                    <Button className='submit-button' onClick={() => close()}>Save</Button>
                </div>
            </div>

        </div>
    )
}

export default NewTeamMember