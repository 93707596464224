import React, { useState } from 'react';
import './style.scss';
import { useNavigate } from "react-router-dom";
import CustomInput from '../../common/CustomInput';
import CustomButton from '../../common/CustomButton';
import AuthLayout from '../authlayout';
import BGImage from '../../assets/images/authentication/hapto-background.png'

const Signup = (props) => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [email, setEmail] = useState(null)
    const [phone, setPhone] = useState(null)
    const [password, setPassword] = useState(null)
    const [confirmPassword, setConfirmPassword] = useState(null)
    const [companyName, setCompanyName] = useState(null)
    const [role, setRole] = useState(null)
    const [department, setDepartment] = useState(null)
    const [pincode, setPincode] = useState(null)
    const [city, setCity] = useState(null)
    const [country, setCountry] = useState(null)


    const handleSignup = () => {
        navigate('/otp')
    }

    return (
        <AuthLayout>
            <div className='signup-container'>
                <img src={BGImage} alt="bg-image" className="login-bg-image" />
                <div className='log-in-wrapper'>
                    <div className='old-user'>Already Registered?</div>
                    <div className='login' onClick={() => navigate('/login')}>
                        Log In
                    </div>
                </div>
                <div className='card-wrapper'>
                    <div className='bg-dots'>
                        {Array(144).fill().map((_, index) => {
                            return <div key={index} className='dot-item' />
                        })}
                    </div>
                    <div className='card-head'>Let's get started</div>
                    <div className='form-wrapper'>
                        <CustomInput half required label="First Name" value={firstName} onChange={setFirstName} />
                        <CustomInput half required label="Last Name" value={lastName} onChange={setLastName} />
                        <CustomInput half required label="Work Email Address" value={email} onChange={setEmail} />
                        <CustomInput half required label="Phone Number" value={phone} onChange={setPhone} />
                        <CustomInput half required protect label="Set Password" value={password} onChange={setPassword} />
                        <CustomInput half required protect label="Confirm Password" value={confirmPassword} onChange={setConfirmPassword} />
                        <CustomInput half required label="Company Name" value={companyName} onChange={setCompanyName} />
                        <CustomInput half required label="Role" value={role} onChange={setRole} />
                        <CustomInput half label="Department" value={department} onChange={setDepartment} />
                        <CustomInput half required label="Pincode" value={pincode} onChange={setPincode} />
                        <CustomInput half label="City" value={city} onChange={setCity} />
                        <CustomInput half label="Country" value={country} onChange={setCountry} />
                    </div>
                    <CustomButton label="Sign Up" onClick={handleSignup} />
                </div>
            </div>
        </AuthLayout>
    )
}

export default Signup