import React, { useEffect } from 'react';
import './style.scss'

const CustomModal = (props) => {

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => { document.body.style.overflow = 'scroll' };
    }, [])

    return (
        <div className='hapto-modal-container' onClick={() => props.close()}>
            <div className='hapto-modal-inner-container' onClick={(event) => event.stopPropagation()}>
                {props.children}
            </div>
        </div>
    )
}

export default CustomModal