import React from 'react';
import './style.scss';

const StackedProgress = (props) => {
    const { chartData } = props;
    const chartColor = ['#4BEB5B', '#4C9BEB', '#F39223'];

    const getPercentage = (now, max) => {
        return (now / max) * 100;
    };

    const getMax = (list) => {
        let max = 0;
        list?.map((inner) => (max = max + inner.value));
        return max;
    };

    return (
        <div className='stacked-progress-container'>
            <div className='status-list hapto-flex'>
                {chartData[1].status.map((item, index) => {
                    return (
                        <div key={index} className='status-item'>
                            <div className='status-color' style={{ backgroundColor: chartColor[index] }} />
                            <div className='hapto-text-light'>{item.label}</div>
                        </div>
                    )
                })}
            </div>

            {chartData.map((data, index) => {
                return (
                    <div className='chart-item hapto-flex' key={index}>
                        <div className='hapto-text-dark'>{data.label}</div>
                        <div className='progress-item'>
                            {data.status.map((status, statusIndex) => {
                                const statusLength = data.status.length - 1
                                return (
                                    <div
                                        key={statusIndex}
                                        role="progressbar"
                                        className="inner-progress"
                                        style={{
                                            width: `${getPercentage(status.value, getMax(chartData[1].status))}%`,
                                            background: chartColor[status.id - 1],
                                            borderTopLeftRadius: statusIndex === 0 ? 50 : 0,
                                            borderBottomLeftRadius: statusIndex === 0 ? 50 : 0,
                                            borderTopRightRadius: statusIndex === statusLength ? 50 : 0,
                                            borderBottomRightRadius: statusIndex === statusLength ? 50 : 0
                                        }}
                                        aria-valuenow={status.value}
                                        aria-valuemin={0}
                                        aria-valuemax={getMax(chartData[1].status)}
                                    ></div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default StackedProgress