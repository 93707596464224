export const MyTasksListData = [
    {
        desc: 'Complete user research for new website',
        due: '26/03/2022',
        type: 'general',
        department: 'Design',
        priority: 'High',
        status: 'In Progress',
        id:1
    },
    {
        desc: 'Work on identification of competitors',
        due: '26/03/2022',
        type: 'general',
        department: 'Design',
        priority: 'High',
        status: 'In Progress',
        id:2
    },
    {
        desc: 'Design low fidelity wireframes',
        due: '26/03/2022',
        type: 'general',
        department: 'Design',
        priority: 'High',
        status: 'In Progress',
        id:3
    },
    {
        desc: 'Work on user journey',
        due: '26/03/2022',
        type: 'general',
        department: 'Design',
        priority: 'Low',
        status: 'In Progress',
        id:4
    },
    {
        desc: 'Organise data collected',
        due: '26/03/2022',
        type: 'general',
        department: 'Design',
        priority: 'Medium',
        status: 'In Progress',
        id:5
    }
]

export const MyTasksCompletedListData = [
    {
        desc: 'Complete user research for new website',
        due: '26/03/2022',
        type: 'general',
        department: 'Design',
        priority: 'High',
        status: 'Completed',
        id:6
    },
    {
        desc: 'Complete user research for new website',
        due: '26/03/2022',
        type: 'general',
        department: 'Design',
        priority: 'High',
        status: 'Completed',
        id:7
    },
    {
        desc: 'Complete user research for new website',
        due: '26/03/2022',
        type: 'general',
        department: 'Design',
        priority: 'High',
        status: 'Completed',
        id:8
    }
]