import React from 'react';
import DonutChart from './charts/donut';
import StackedArea from './charts/stackedArea';
import StackedProgress from './charts/stackedProgress';
import './style.scss';


const Reports = (props) => {
  const timeData = [
    { label: 'Hayden', status: [{ label: 'Ahead', id: 1, value: 60 }, { label: 'Ontime', id: 3, value: 40 }] },
    { label: 'Aspen', status: [{ label: 'Ahead', id: 1, value: 30 }, { label: 'Behind', id: 2, value: 30 }, { label: 'Ontime', id: 3, value: 40 }] },
    { label: 'Jacob', status: [{ label: 'Behind', id: 2, value: 80 }, { label: 'Ontime', id: 3, value: 20 }] },
    { label: 'Holder', status: [{ label: 'Ahead', id: 1, value: 60 }, { label: 'Behind', id: 2, value: 10 }, { label: 'Ontime', id: 3, value: 30 }] },
  ]

  const progressData = [
    { label: 'Hayden', status: [{ label: 'Completed', id: 1, value: 60 }, { label: 'Overdue', id: 3, value: 40 }] },
    { label: 'Aspen', status: [{ label: 'Completed', id: 1, value: 30 }, { label: 'Remaining', id: 2, value: 30 }, { label: 'Overdue', id: 3, value: 40 }] },
    { label: 'Jacob', status: [{ label: 'Remaining', id: 2, value: 80 }, { label: 'Overdue', id: 3, value: 20 }] },
    { label: 'Holder', status: [{ label: 'Completed', id: 1, value: 60 }, { label: 'Remaining', id: 2, value: 10 }, { label: 'Overdue', id: 3, value: 30 }] },
  ]

  const healthData = [
    { label: 'Time', value: '14% ahead of schedule' },
    { label: 'Tasks', value: '12 Tasks to be completed' },
    { label: 'Workload', value: '5 Tasks overdue' },
    { label: 'Progress', value: '14% complete' },

  ]

  return (
    <div className='reports-container'>
      <div className='reports-head hapto-text-dark'>Project 1</div>

      <div className='hapto-flex area-donut-wrapper'>
        <div className='area-chart-wrapper'>
          <StackedArea />
        </div>
        <div className='donut-chart-container'>
          <DonutChart />
        </div>
      </div>

      <div className='hapto-flex area-donut-wrapper'>
        <div className='card-container time-container'>
          <div className='hapto-text-dark card-head'>Time</div>
          <StackedProgress chartData={timeData} />
        </div>

        <div className='card-container task-container'>
          <div className='hapto-text-dark card-head'>Task Workload</div>
          <StackedProgress chartData={progressData} />
        </div>

        <div className='card-container health-container'>
          <div className='hapto-text-dark card-head'>Health</div>

          {healthData.map((item, index) => {
            return (
              <div key={index} className='health-item hapto-flex'>
                <div className='hapto-text-dark'>{item.label}</div>
                <div className='hapto-text-light'>{item.value}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Reports