import { combineReducers } from 'redux';
import navbarReducer from '../redux/navbar/reducer';
import quickActionReducer from '../redux/quickactions/reducer';

const appReducer = combineReducers({
    navbar: navbarReducer,
    quickAction: quickActionReducer
});


const rootReducer = (state, action) => {
    return appReducer(state, action)
}

export default rootReducer