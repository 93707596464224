import React, { useState } from "react";
import './style.scss';
import ProfileImage from '../../../assets/images/profile/profile.png';
import CustomInput from "../../../common/CustomInput";
import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";

const CompanyProfile = (props) => {
    const [companyName, setCompanyName] = useState(null)
    const [totalTeamMembers, setTotalTeamMembers] = useState(null)
    const [companyWebsite, setCompanyWebsite] = useState(null)
    const [yearFounded, setYearFounded] = useState(null)
    const [state, setState] = useState(null)
    const [city, setCity] = useState(null)
    const [country, setCountry] = useState(null)
    const [streetName, setStreetName] = useState(null)
    const [zipCode, setZipCode] = useState(null)
    const [compenyPhoneNumber, setCompanyPhoneNumber] = useState(null);

    const [showAddObjective, setShowAddObjective] = useState(false)

    return (
        <div className='company-profile-container'>
            <div className="top-section">
                <div className="profile-image">
                    <div className='action-item'><EditOutlined /></div>
                    <img src={ProfileImage} className="profile-fit" alt="profile" />
                </div>

                <div className="company-details">
                    <CustomInput half label="Company Name" value={companyName} onChange={setCompanyName} />
                    <CustomInput half label="Total Team Members" value={totalTeamMembers} onChange={setTotalTeamMembers} />
                    <CustomInput half label="Company Website" value={companyWebsite} onChange={setCompanyWebsite} />
                    <CustomInput half label="Year Founded" value={yearFounded} onChange={setYearFounded} />
                </div>

                <div className="progress">
                    <div className="hapto-text-dark">Total Goal Progress</div>
                    <div className="progress-container">
                        <div className="outer-progress">
                            <div className="inner-progress hapto-text-dark">
                                25%
                            </div>
                        </div>
                        <div className="status hapto-text-dark">On Track</div>
                    </div>
                </div>
            </div>

            <div className="middle-section">
                <div className='hapto-text-dark'>Location Details</div>
                <div className="middle-section-flex">
                    <CustomInput three label="State" value={state} onChange={setState} />
                    <CustomInput three label="City" value={city} onChange={setCity} />
                    <CustomInput three label="Country" value={country} onChange={setCountry} />
                    <CustomInput three label="Street Name" value={streetName} onChange={setStreetName} />
                    <CustomInput three label="Zip Code" value={zipCode} onChange={setZipCode} />
                    <CustomInput three label="Company Phone Number" value={compenyPhoneNumber} onChange={setCompanyPhoneNumber} />
                </div>
            </div>

            <div className='hapto-text-dark'>Company Values</div>
            <div className="bottom-section">
                <div className="section-item">
                    <div className="item-label">Core Value</div>
                    <div className="item-value hapto-text-light">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero.</div>

                    <div className="item-label">Company Value</div>
                    <div className="item-value hapto-text-light">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.</div>
                    <Button className="add-button">+ Add Company Value</Button>
                </div>

                <div className="section-item">
                    <div className="edit-button" onClick={() => setShowAddObjective(!showAddObjective)}>{showAddObjective ? 'Save' : 'Edit'}</div>
                    {showAddObjective && <div className="cancel-button" onClick={() => setShowAddObjective(!showAddObjective)}>Cancel</div>}
                    <div className="item-label">Organisational Objectives</div>
                    <div className="item-flex">
                        <div className="item-color" />
                        <div className="item-flex-value hapto-text-light">Promotion of competitive position</div>
                    </div>
                    <div className="item-flex">
                        <div className="item-color" />
                        <div className="item-flex-value hapto-text-light">Increase in income</div>
                    </div>
                    <div className="item-flex">
                        <div className="item-color" />
                        <div className="item-flex-value hapto-text-light">Reduction in development costs</div>
                    </div>
                    {showAddObjective && <Button className="add-button" onClick={() => setShowAddObjective(!showAddObjective)}>+ Add Organisational Objective</Button>}
                </div>
            </div>
        </div>
    )
}

export default CompanyProfile